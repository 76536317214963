import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import { PhraseGroupState, actions } from './modules/PhraseGroupSlice';
import LoadingSection from '../../components/LoadingSection';
import { selectPhraseGroupData } from './modules/PhraseGroupSelector';
import PhraseGroupList from './PhraseGroupList';
import PhraseGroupAddModal, { PhraseGroupAddModalRef } from './PhraseGroupAddModal';
import { defaultPagination } from '../../utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

function PhraseGroupPage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const phraseGroups: PhraseGroupState = useSelector(selectPhraseGroupData, shallowEqual);
  const [showLoading, setShowLoading] = useState(true);
  const addRef = useRef<PhraseGroupAddModalRef>(null);

  const fetchData = (
    size = phraseGroups.meta?.per_page || defaultPagination.size,
    page = phraseGroups?.meta?.current_page || defaultPagination.page,
  ) => {
    return dispatch(actions.fetch({ size, page }));
  };

  useEffect(() => {
    if (!phraseGroups?.loading) {
      const size = Number(searchParams.get('size')) || defaultPagination.size;
      const page = Number(searchParams.get('page')) || defaultPagination.page;
      fetchData(size, page);
    }
  }, [location, searchParams]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowPhraseGroupList = () => {
    if (showLoading || phraseGroups.loading || phraseGroups.error) {
      return <LoadingSection error={phraseGroups.error} />;
    }
    return <PhraseGroupList state={phraseGroups} setSearchParams={setSearchParams} />;
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/phrase_group', name: 'Phrase Group' },
                ]}
              />
            </Box>
            <Box data-testid="alerts-refresh-button">
              <Button
                onClick={() => {
                  if (addRef.current) addRef.current.open();
                }}
                startIcon={<Add />}
                color="primary"
                variant="outlined"
              >
                Add Phrase Group
              </Button>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {!(showLoading || phraseGroups.loading || phraseGroups.error) && (
              <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {phraseGroups?.meta?.total || 0} Phrase Group
                {(phraseGroups?.meta?.total || 0) > 1 ? 's' : ''}
              </Typography>
            )}
            <ShowPhraseGroupList />
          </Paper>
        </Grid>
      </Grid>
      <PhraseGroupAddModal ref={addRef} />
    </div>
  );
}

export default PhraseGroupPage;
