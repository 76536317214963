import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import { PhraseGroupResponse } from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions } from './modules/PhraseGroupSlice';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { errorUpdateMessage, updatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface PhraseGroupEditModalProps {
  phraseGroup: PhraseGroupResponse;
}

export interface PhraseGroupEditModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  description?: string;
  image?: string;
};

const PhraseGroupEditModal = forwardRef(
  (props: PhraseGroupEditModalProps, ref: Ref<PhraseGroupEditModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const defaultValues: FormProps = {
      title: props.phraseGroup.title,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .putPhraseGroup<PhraseGroupResponse>(props.phraseGroup.id, values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(actions.fetchDetailSuccess(response));
        dispatch(alertActions.setAlert(updatedMessage('Phrase Group', response.title)));
      } else {
        dispatch(alertActions.setAlert(errorUpdateMessage('Phrase Group', values.title)));
      }

      handleClose();
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="edit-modal-title">
            EDIT PHRASE GROUP
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default PhraseGroupEditModal;
