import { all, call, spawn, StrictEffect } from 'redux-saga/effects';

import userProfileSaga from './modules/auth/modules/UserProfileSaga';
import languageSaga from './modules/language/modules/LanguageSaga';
import OverviewSaga from './modules/dashboard/modules/OverviewSaga';
import LessonSaga from './modules/lesson/modules/LessonSaga';
import LessonGroupSaga from './modules/lesson_group/modules/LessonGroupSaga';
import LevelSaga from './modules/level/modules/LevelSaga';
import SituationSaga from './modules/situation/modules/SituationSaga';
import SituationGroupSaga from './modules/situation_group/modules/SituationGroupSaga';
import PhraseSaga from './modules/phrase/modules/PhraseSaga';
import PhraseGroupSaga from './modules/phrase_group/modules/PhraseGroupSaga';
import SentenceSaga from './modules/sentence/modules/SentenceSaga';
import SentenceTypeSaga from './modules/sentence_type/modules/SentenceTypeSaga';
import SentenceTemplateSaga from './modules/sentence_template/modules/SentenceTemplateSaga';
import QuestionSaga from './modules/question/modules/QuestionSaga';
import LessonTemplateSaga from './modules/lesson_template/modules/LessonTemplateSaga';

export default function* rootSaga(): Generator<StrictEffect, void, unknown> {
  const sagas = [
    userProfileSaga,
    languageSaga,
    OverviewSaga,
    LessonSaga,
    LessonGroupSaga,
    LevelSaga,
    SituationSaga,
    SituationGroupSaga,
    PhraseSaga,
    PhraseGroupSaga,
    SentenceSaga,
    SentenceTypeSaga,
    SentenceTemplateSaga,
    QuestionSaga,
    LessonTemplateSaga,
  ];

  yield all(
    sagas.map((saga) =>
      // eslint-disable-next-line func-names
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // error
          }
        }
      }),
    ),
  );
}
