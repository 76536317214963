import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  PaginationResponse,
  SentenceTemplateResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './SentenceTemplateSlice';

export function* fetchSentenceTemplateList(
  action: PayloadAction<{ size: number; page: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<SentenceTemplateResponse> = yield call(
      api.getSentenceTemplateList,
      size,
      page,
    );
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchSentenceTemplateId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: SentenceTemplateResponse = yield call(api.getSentenceTemplateById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* SentenceTemplateSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchSentenceTemplateList),
    yield takeEvery(actions.fetchDetail, fetchSentenceTemplateId),
  ]);
}
