import { Route, Routes } from 'react-router-dom';
import ErrorView from '../components/ErrorView';
import Overview from '../modules/dashboard/Overview';
import AuthLayout from '../components/AuthLayout';
import LanguagePage from '../modules/language/LanguagePage';
import LanguageDetailPage from '../modules/language/LanguageDetailPage';
import LessonPage from '../modules/lesson/LessonPage';
import LessonDetailPage from '../modules/lesson/LessonDetailPage';
import LessonGroupPage from '../modules/lesson_group/LessonGroupPage';
import LessonGroupDetailPage from '../modules/lesson_group/LessonGroupDetailPage';
import LevelPage from '../modules/level/LevelPage';
import LevelDetailPage from '../modules/level/LevelDetailPage';
import SituationPage from '../modules/situation/SituationPage';
import SituationDetailPage from '../modules/situation/SituationDetailPage';
import SituationGroupPage from '../modules/situation_group/SituationGroupPage';
import SituationGroupDetailPage from '../modules/situation_group/SituationGroupDetailPage';
import LessonTemplatePage from '../modules/lesson_template/LessonTemplatePage';
import PhrasePage from '../modules/phrase/PhrasePage';
import PhraseDetailPage from '../modules/phrase/PhraseDetailPage';
import PhraseGroupPage from '../modules/phrase_group/PhraseGroupPage';
import PhraseGroupDetailPage from '../modules/phrase_group/PhraseGroupDetailPage';
import SentencePage from '../modules/sentence/SentencePage';
import SentenceDetailPage from '../modules/sentence/SentenceDetailPage';
import SentenceTypePage from '../modules/sentence_type/SentenceTypePage';
import SentenceTypeDetailPage from '../modules/sentence_type/SentenceTypeDetailPage';
import SentenceTemplatePage from '../modules/sentence_template/SentenceTemplatePage';
import SentenceTemplateDetailPage from '../modules/sentence_template/SentenceTemplateDetailPage';
import QuestionPage from '../modules/question/QuestionPage';
import QuestionDetailPage from '../modules/question/QuestionDetailPage';

function AuthRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthLayout>
            <Overview />
          </AuthLayout>
        }
      />
      <Route
        path="/language"
        element={
          <AuthLayout>
            <LanguagePage />
          </AuthLayout>
        }
      />
      <Route
        path="/language/:id"
        element={
          <AuthLayout>
            <LanguageDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/lesson_group/:lesson_group_id/lesson"
        element={
          <AuthLayout>
            <LessonPage />
          </AuthLayout>
        }
      />
      <Route
        path="/lesson_group/:lesson_group_id/lesson/:id"
        element={
          <AuthLayout>
            <LessonDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/lesson_group"
        element={
          <AuthLayout>
            <LessonGroupPage />
          </AuthLayout>
        }
      />
      <Route
        path="/lesson_group/:id"
        element={
          <AuthLayout>
            <LessonGroupDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/lesson_group/:lesson_group_id/lesson_template"
        element={
          <AuthLayout>
            <LessonTemplatePage />
          </AuthLayout>
        }
      />
      <Route
        path="/level"
        element={
          <AuthLayout>
            <LevelPage />
          </AuthLayout>
        }
      />
      <Route
        path="/level/:id"
        element={
          <AuthLayout>
            <LevelDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/situation"
        element={
          <AuthLayout>
            <SituationPage />
          </AuthLayout>
        }
      />
      <Route
        path="/situation/:id"
        element={
          <AuthLayout>
            <SituationDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/situation_group"
        element={
          <AuthLayout>
            <SituationGroupPage />
          </AuthLayout>
        }
      />
      <Route
        path="/situation_group/:id"
        element={
          <AuthLayout>
            <SituationGroupDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/situation_group/:situation_group_id/situation/:id"
        element={
          <AuthLayout>
            <SituationDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/phrase"
        element={
          <AuthLayout>
            <PhrasePage />
          </AuthLayout>
        }
      />
      <Route
        path="/phrase/:id"
        element={
          <AuthLayout>
            <PhraseDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/phrase_group"
        element={
          <AuthLayout>
            <PhraseGroupPage />
          </AuthLayout>
        }
      />
      <Route
        path="/phrase_group/:id"
        element={
          <AuthLayout>
            <PhraseGroupDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/phrase_group/:phrase_group_id/phrase/:id"
        element={
          <AuthLayout>
            <PhraseDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/sentence"
        element={
          <AuthLayout>
            <SentencePage />
          </AuthLayout>
        }
      />
      <Route
        path="/sentence/:id"
        element={
          <AuthLayout>
            <SentenceDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/sentence_type"
        element={
          <AuthLayout>
            <SentenceTypePage />
          </AuthLayout>
        }
      />
      <Route
        path="/sentence_type/:id"
        element={
          <AuthLayout>
            <SentenceTypeDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/sentence_template"
        element={
          <AuthLayout>
            <SentenceTemplatePage />
          </AuthLayout>
        }
      />
      <Route
        path="/sentence_template/:id"
        element={
          <AuthLayout>
            <SentenceTemplateDetailPage />
          </AuthLayout>
        }
      />
      <Route
        path="/question"
        element={
          <AuthLayout>
            <QuestionPage />
          </AuthLayout>
        }
      />
      <Route
        path="/question/:id"
        element={
          <AuthLayout>
            <QuestionDetailPage />
          </AuthLayout>
        }
      />
      <Route path="*" element={<ErrorView />} />
    </Routes>
  );
}

export default AuthRoutes;
