import { Button, DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import { SentenceTypeResponse } from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { deletedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface SentenceTypeDeleteModalProps {
  sentenceType: SentenceTypeResponse;
}

export interface SentenceTypeDeleteModalRef {
  open: () => void;
  close: () => void;
}

const SentenceTypeDeleteModal = forwardRef(
  (props: SentenceTypeDeleteModalProps, ref: Ref<SentenceTypeDeleteModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onClick = async () => {
      await api.deleteSentenceType(props.sentenceType.id);
      handleClose();
      dispatch(alertActions.setAlert(deletedMessage('Sentence Type', props.sentenceType.title)));
      navigate('/sentence_type');
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <ModalDialogTitle onClose={handleClose} id="delete-modal-title">
          DELETE SENTENCE TYPE
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <Typography fontStyle={'italic'} gutterBottom>
              Are you sure you want to delete this sentence type?
            </Typography>
            <Typography gutterBottom>Title: {props.sentenceType.title}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onClick}>Delete</Button>
        </DialogActions>
      </ModalDialog>
    );
  },
);

export default SentenceTypeDeleteModal;
