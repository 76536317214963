import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LoadingSection from '../../components/LoadingSection';
import Breadcrumbs from '../../components/Breadcrumbs';
import LessonTemplatePhraseGroup from './components/LessonTemplatePhraseGroup';
import { actions } from './modules/LessonTemplateSlice';
import { selectLessonTemplateData } from './modules/LessonTemplateSelector';
import LessonTemplatePhraseGroupSentenceTemplate from './components/LessonTemplatePhraseGroupSentenceTemplate';
import LessonTemplatePhraseGroupSentenceGenerate from './components/LessonTemplatePhraseGroupSentenceGenerate';
import LessonTemplateAddQuestionModal, {
  QuestionAddModalRef,
} from './components/LessonTemplateAddQuestionModal';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paperGroup: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const actionOptions = ['Add New Question'];

function LessonTemplatePage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(true);
  const lessonTemplate = useSelector(selectLessonTemplateData, shallowEqual);
  const params = useParams();
  const [expanded, setExpanded] = useState<Array<string | false>>([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const addRef = useRef<QuestionAddModalRef>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setOpen(false);

    console.log(lessonTemplate);
    if (index === 0 && addRef.current) {
      console.log('open');
      addRef.current?.open();
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const fetchData = (lessonGroupId: string) => {
    return dispatch(actions.fetch({ lesson_group_id: lessonGroupId }));
  };

  const handleQuestionClose = (refetch?: boolean) => {
    if (refetch) {
      fetchData(params.lesson_group_id || '');
    }
  };

  useEffect(() => {
    if (!lessonTemplate?.loading) {
      fetchData(params.lesson_group_id || '');
    }
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/lesson_group', name: 'Lesson Group' },
                  {
                    path: '/lesson_group/' + params.lesson_group_id,
                    name: params.lesson_group_id || 'Unknown',
                  },
                  {
                    path: '/lesson_group/' + params.lesson_group_id + '/lesson_template',
                    name: 'Lesson Template',
                  },
                ]}
              />
            </Box>
            <Box>
              <ButtonGroup ref={anchorRef}>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={handleToggle}
                >
                  Actions
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="button-menu" autoFocusItem>
                          {actionOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <LessonTemplatePhraseGroup lesson_group_id={params.lesson_group_id || ''} />
          <Fragment>
            {showLoading || lessonTemplate.loading || lessonTemplate.error ? (
              <LoadingSection error={lessonTemplate.error} />
            ) : (
              <Fragment>
                {(lessonTemplate.questionList || []).map((item, index) => (
                  <Accordion
                    defaultExpanded={index == 0}
                    key={`${index}-${item.id}`}
                    expanded={
                      expanded.length >= index + 1 &&
                      expanded[parseInt(index.toString())] === `panel${index}`
                    }
                    onChange={(event, isExpanded) => {
                      const newExpanded: Array<string | false> = new Array(index + 1).fill(false);
                      for (let k = 0; k < expanded.length; k++) {
                        newExpanded[parseInt(k.toString())] = expanded[parseInt(k.toString())];
                      }
                      const value = isExpanded ? `panel${index}` : false;
                      newExpanded[parseInt(index.toString())] = value;
                      setExpanded(newExpanded);
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Question {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LessonTemplatePhraseGroupSentenceTemplate
                        index={index}
                        question_id={item.id.toString()}
                        lesson_group_id={params.lesson_group_id || ''}
                        sentence_template={item.sentence_template}
                        classes={classes}
                      />
                      <LessonTemplatePhraseGroupSentenceGenerate
                        index={index}
                        classes={classes}
                        question_id={item.id.toString()}
                        lesson_group_id={item.lesson_group_id}
                        generate_sentence={item.generate_sentence}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Fragment>
            )}
          </Fragment>
        </Grid>
      </Grid>
      {lessonTemplate.lesson_group_id && (
        <LessonTemplateAddQuestionModal
          lesson_group_id={lessonTemplate.lesson_group_id}
          ref={addRef}
          handleClose={handleQuestionClose}
        />
      )}
    </div>
  );
}

export default LessonTemplatePage;
