import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
import { Grid, Link, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { OverviewState, actions } from './modules/OverviewSlice';
import { selectOverviewData } from './modules/OverviewSelector';
import LoadingSection from '../../components/LoadingSection';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

function Overview() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const overview: OverviewState = useSelector(selectOverviewData, shallowEqual);
  const [showLoading, setShowLoading] = useState(true);

  const fetchData = () => {
    return dispatch(actions.fetch());
  };

  useEffect(() => {
    if (!overview?.loading) {
      fetchData();
    }
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  return (
    <Fragment>
      {showLoading || overview.loading || overview.error ? (
        <LoadingSection error={overview.error} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <Fragment>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                  Number of Languages
                </Typography>
                <Typography component="p" variant="h4" sx={{ flex: 1 }}>
                  {overview.data?.num_languages || 0}
                </Typography>
                <div>
                  <Link color="primary" onClick={() => navigate('/language')}>
                    View Languages
                  </Link>
                </div>
              </Fragment>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <Fragment>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                  Number of Lesson Groups
                </Typography>
                <Typography component="p" variant="h4" sx={{ flex: 1 }}>
                  {overview.data?.num_lesson_groups || 0}
                </Typography>
                <div>
                  <Link color="primary" onClick={() => navigate('/lesson_group')}>
                    View Lesson Groups
                  </Link>
                </div>
              </Fragment>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <Fragment>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                  Number of Situation Groups
                </Typography>
                <Typography component="p" variant="h4" sx={{ flex: 1 }}>
                  {overview.data?.num_situation_groups || 0}
                </Typography>
                <div>
                  <Link color="primary" onClick={() => navigate('/situation_group')}>
                    View Situation Groups
                  </Link>
                </div>
              </Fragment>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <Fragment>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                  Number of Situations
                </Typography>
                <Typography component="p" variant="h4" sx={{ flex: 1 }}>
                  {overview.data?.num_situations || 0}
                </Typography>
                <div>
                  <Link color="primary" onClick={() => navigate('/situation')}>
                    View Situations
                  </Link>
                </div>
              </Fragment>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default Overview;
