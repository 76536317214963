import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  PaginationResponse,
  SituationResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './SituationSlice';

export function* fetchSituationList(
  action: PayloadAction<{ size: number; page: number; situation_group_id?: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<SituationResponse> = yield call(
      api.getSituationList,
      size,
      page,
      action.payload?.situation_group_id,
    );
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchSituationId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: SituationResponse = yield call(api.getSituationById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* SituationSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchSituationList),
    yield takeEvery(actions.fetchDetail, fetchSituationId),
  ]);
}
