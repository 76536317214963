import { Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';

import { actions } from '../modules/LessonTemplateSlice';
import { SentenceTemplateResponse } from '../../../utilities/apiUtilities';
import SentenceTemplateDeleteModal, {
  SentenceTemplateDeleteModalRef,
} from './sentence_template/DeleteSenetenceTemplateModal';
import SentenceTemplateAddModal, {
  SentenceTemplateAddModalRef,
} from './sentence_template/AddSentenceTemplateModal';
import { Edit } from '@mui/icons-material';
import SentenceTemplateEditModal, {
  SentenceTemplateEditModalRef,
} from './sentence_template/EditSentenceTemplateModal';

interface LessonTemplatePhraseGroupSentenceTemplateProps {
  index: number;
  question_id: string;
  lesson_group_id: string;
  sentence_template: SentenceTemplateResponse[];
  classes: Record<'root' | 'paperGroup' | 'paper', string>;
}

const LessonTemplatePhraseGroupSentenceTemplate: React.FC<
  LessonTemplatePhraseGroupSentenceTemplateProps
> = ({ index, question_id, lesson_group_id, sentence_template, classes }) => {
  const dispatch = useDispatch();
  const [addQuestionId, setAddQuestionId] = useState<number | undefined>(undefined);
  const [editQuestion, setEditQuestion] = useState<SentenceTemplateResponse | undefined>(undefined);
  const [deleteQuestion, setDeleteQuestion] = useState<SentenceTemplateResponse | undefined>(
    undefined,
  );
  const addRef = useRef<SentenceTemplateAddModalRef>(null);
  const editRef = useRef<SentenceTemplateEditModalRef>(null);
  const deleteRef = useRef<SentenceTemplateDeleteModalRef>(null);

  const onClickAdd = async () => {
    setAddQuestionId(parseFloat(question_id));
  };

  const onCloseAddModal = () => {
    setAddQuestionId(undefined);
  };

  const onSuccessAdd = (sentenceTemplate: SentenceTemplateResponse) => {
    dispatch(
      actions.fetchOneQuestion({
        question_id: sentenceTemplate.question_id.toString(),
        lesson_group_id,
      }),
    );
    onCloseAddModal();
  };

  const onClickEdit = async (sentenceTemplate: SentenceTemplateResponse) => {
    setEditQuestion(sentenceTemplate);
  };

  const onCloseEditModal = () => {
    setEditQuestion(undefined);
  };

  const onSuccessEdit = (sentenceTemplate: SentenceTemplateResponse) => {
    dispatch(
      actions.fetchOneQuestion({
        question_id: sentenceTemplate.question_id.toString(),
        lesson_group_id,
      }),
    );
    onCloseEditModal();
  };

  const onClickDelete = async (sentenceTemplate: SentenceTemplateResponse) => {
    setDeleteQuestion(sentenceTemplate);
  };

  const onCloseDeleteModal = (sentenceTemplate?: SentenceTemplateResponse) => {
    if (sentenceTemplate) {
      dispatch(
        actions.fetchOneQuestion({
          question_id: sentenceTemplate.question_id.toString(),
          lesson_group_id,
        }),
      );
    }
    setDeleteQuestion(undefined);
  };

  useEffect(() => {
    if (editQuestion && editRef.current) {
      editRef.current.open();
    }
  }, [editQuestion]);

  useEffect(() => {
    if (addQuestionId && addRef.current) {
      addRef.current.open();
    }
  }, [addQuestionId]);

  useEffect(() => {
    if (deleteQuestion && deleteRef.current) {
      deleteRef.current.open();
    }
  }, [deleteQuestion]);

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1} alignSelf="center">
          <Typography variant="subtitle1" component="h5">
            SENTENCE TEMPLETE
          </Typography>
        </Box>
        <Box>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ marginRight: 0.5 }}
            onClick={onClickAdd}
          >
            ADD NEW
          </Button>
        </Box>
      </Box>
      <TableContainer className={classes.paperGroup} component={Paper}>
        <Table className={classes.paper} aria-label="Sentence table">
          {/* <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Question ID</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {(sentence_template || []).map((innerItem, innerIndex) => (
              <TableRow
                key={`${innerItem.id}-${index}-${innerIndex}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{innerItem.title}</TableCell>
                <TableCell>{innerItem.number}</TableCell>
                <TableCell>{innerItem.question_id}</TableCell>
                <TableCell align="right">
                  <Button color="primary" onClick={() => onClickEdit(innerItem)}>
                    <Edit />
                  </Button>
                  <Button color="warning" onClick={() => onClickDelete(innerItem)}>
                    <GridDeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {addQuestionId && (
        <SentenceTemplateAddModal
          ref={addRef}
          question_id={addQuestionId}
          onSuccessCallback={onSuccessAdd}
          onCloseCallback={onCloseAddModal}
        />
      )}
      {editQuestion && (
        <SentenceTemplateEditModal
          ref={editRef}
          sentenceTemplate={editQuestion}
          onSuccessCallback={onSuccessEdit}
          onCloseCallback={onCloseEditModal}
        />
      )}
      {deleteQuestion && (
        <SentenceTemplateDeleteModal
          ref={deleteRef}
          sentenceTemplate={deleteQuestion}
          onCloseCallback={onCloseDeleteModal}
        />
      )}
    </Fragment>
  );
};

export default LessonTemplatePhraseGroupSentenceTemplate;
