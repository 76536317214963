import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import {
  LessonGroupResponse,
  PaginationResponse,
  QuestionListResponse,
  SentenceTypeResponse,
} from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions } from './modules/QuestionSlice';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { errorUpdateMessage, updatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface QuestionEditModalProps {
  question: QuestionListResponse;
}

export interface QuestionEditModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  lesson_group_id: string;
  sentence_type_id: string;
  gtp_prompt?: string;
};

const QuestionEditModal = forwardRef(
  (props: QuestionEditModalProps, ref: Ref<QuestionEditModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [lessonGroupOptions, setLessonGroupOptions] = useState<{ id: string; label: string }[]>(
      [],
    );
    const [sentenceTypeOptions, setSentenceTypeOptions] = useState<{ id: string; label: string }[]>(
      [],
    );

    const defaultValues: FormProps = {
      lesson_group_id: props.question.lesson_group_id,
      sentence_type_id: props.question.sentence_type_id,
      gtp_prompt: props.question.gpt_prompt,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .putQuestion<QuestionListResponse>(props.question.id, values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(actions.fetchDetailSuccess(response));
        dispatch(alertActions.setAlert(updatedMessage('Phrase', 'Question #' + response.id)));
      } else {
        dispatch(
          alertActions.setAlert(errorUpdateMessage('Phrase', 'Question #' + props.question.id)),
        );
      }

      handleClose();
    };

    const formatList = (list: Array<LessonGroupResponse | SentenceTypeResponse>) => {
      return list.map((item) => {
        let label;
        if ('title' in item) {
          label = item.title;
        } else {
          label = 'Unknown';
        }

        return { id: item.id, label: label };
      });
    };

    useEffect(() => {
      (async () => {
        const size = 100;
        const page = 0;
        const lessonGroups = await api
          .getLessonGroupList<PaginationResponse<LessonGroupResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        const sentenceTypes = await api
          .getSentenceTypeList<PaginationResponse<SentenceTypeResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        setLessonGroupOptions(lessonGroups);
        setSentenceTypeOptions(sentenceTypes);
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="edit-modal-title">
            EDIT QUESTION
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <SelectElement
                name={'lesson_group_id'}
                label={'Lesson Group'}
                options={lessonGroupOptions}
                fullWidth
              />
              <SelectElement
                name={'sentence_type_id'}
                label={'Sentence Type'}
                options={sentenceTypeOptions}
                fullWidth
              />
              <TextFieldElement multiline minRows={3} name={'gtp_prompt'} label={'GTP Prompt'} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default QuestionEditModal;
