import { Fragment, useEffect, useState } from 'react';
import {
  LanguageResponse,
  LessonDetailResponse,
  TranslatedSentenceResponse,
} from '../../../utilities/apiUtilities';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../modules/LessonSlice';
import { selectLanguageList, selectLessonTranslated } from '../modules/LessonSelector';
import LoadingSection from '../../../components/LoadingSection';
import LessionDetailQuestionCell from './LessionDetailQuestionCell';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paperGroup: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  gutter: {
    marginLeft: theme.spacing(1),
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(title: string, languageList: readonly LanguageResponse[], theme: Theme) {
  return {
    fontWeight:
      languageList.map((item) => item.title).indexOf(title) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface LessonDetailQuestionsProps {
  lesson: LessonDetailResponse;
}

const LessonDetailQuestion = ({ lesson }: LessonDetailQuestionsProps): JSX.Element => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | false>(false);
  const languageList = useSelector(selectLanguageList);
  const translate = useSelector(selectLessonTranslated);

  const fetchTranslation = (lessonId: string, selectedLanguage: LanguageResponse) => {
    return dispatch(
      actions.fetchTranslatedSentence({
        lessonId: lessonId,
        language: selectedLanguage.title || '',
        languageId: selectedLanguage.id || '',
      }),
    );
  };

  const selectLanguage = (language: LanguageResponse) => {
    fetchTranslation(lesson?.id || '', language);
  };

  const lookUpSentenceTranslated = (
    sentence_id: string,
  ): TranslatedSentenceResponse | undefined => {
    let result = undefined;
    (translate?.sentence || []).forEach((item) => {
      if (item.sentence_id == sentence_id) {
        result = item;
      }
    });

    return result;
  };

  useEffect(() => {
    if (lesson.id && languageList.length > 0) {
      fetchTranslation(lesson?.id, languageList[0]);
    }
  }, []);

  const ShowLessonTranslated = () => {
    if (translate?.loading || translate?.error) {
      return <LoadingSection error={translate.error} />;
    }

    return (
      <Fragment>
        {(lesson.questions || []).map((item, index) => (
          <Accordion
            defaultExpanded={index == 0}
            key={item.question_id}
            expanded={expanded === `panel${index}`}
            onChange={(event, isExpanded) => setExpanded(isExpanded ? `panel${index}` : false)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Question {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="block">
                <Grid container>
                  <Grid item className={classes.gutter} md={6}>
                    <Typography variant="subtitle1" component="h5">
                      English
                    </Typography>
                  </Grid>
                  <Grid item className={classes.gutter} md={5.5}>
                    <Typography variant="subtitle1" component="h5">
                      {translate.language || 'No language selected'}
                    </Typography>
                  </Grid>
                </Grid>
                {(item.sentences || []).map((innerItem, innerIndex) => {
                  const sentence = lookUpSentenceTranslated(innerItem.id);
                  return (
                    <Grid container key={`${index}-${innerIndex}`} mt={3}>
                      <Grid item className={classes.gutter} md={6}>
                        <Card>
                          <CardContent>
                            <Typography component="p">{innerItem.title}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item className={classes.gutter} md={5.5}>
                        <Card>
                          <CardContent>
                            <LessionDetailQuestionCell
                              innerItem={innerItem}
                              sentence={sentence}
                              translate={translate}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Fragment>
    );
  };

  return (
    <Box className={classes.root}>
      <Box display="flex">
        <Box flexGrow={1} alignSelf="center">
          <Typography>Question List</Typography>
        </Box>
        <Box>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={translate?.language || ''}
              onChange={(e) => {
                const language = languageList.find((item) => item.title === e.target.value);
                if (language) {
                  selectLanguage(language as LanguageResponse);
                }
              }}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Language</em>;
                }

                return selected;
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value="">
                <em>Language</em>
              </MenuItem>
              {(languageList || []).map((name) => (
                <MenuItem
                  key={name.id}
                  value={name.title}
                  style={getStyles(name.title, languageList, theme)}
                >
                  {name.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <ShowLessonTranslated />
    </Box>
  );
};

export default LessonDetailQuestion;
