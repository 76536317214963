import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorResponse,
  LanguageResponse,
  LessonDetailResponse,
  LessonResponse,
  PaginationMetaResponse,
  PaginationResponse,
  TranslatedSentenceResponse,
} from '../../../utilities/apiUtilities';

export type LessonQuestionTranslate = {
  lesson_id?: string;
  language_id?: string;
  language?: string;
  sentence: TranslatedSentenceResponse[];
  loading: boolean;
  error?: ErrorResponse;
  individualLoading?: boolean;
  individualError?: ErrorResponse;
  audioLoading?: boolean;
};

export type LessonState = {
  data: LessonResponse[];
  loading: boolean;
  paginationLoading: boolean;
  error?: ErrorResponse;
  meta?: PaginationMetaResponse;
  lessonDetail?: LessonDetailResponse;
  languageList: LanguageResponse[];
  translate: LessonQuestionTranslate;
};

export const initialState: LessonState = {
  data: [],
  loading: false,
  paginationLoading: false,
  languageList: [],
  translate: {
    sentence: [],
    loading: true,
  },
};

export const LessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ size: number; page: number; lesson_group_id?: number }>) {
      if (action.payload.page > 1) {
        state.paginationLoading = true;
      } else {
        state.loading = true;
      }
      state.loading = true;
      state.error = undefined;
      state.data = [];
    },
    fetchSuccess(state, action: PayloadAction<PaginationResponse<LessonResponse>>) {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.data = [];
      state.meta = undefined;
      state.error = action.payload;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchDetail(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
      state.error = undefined;
      state.lessonDetail = undefined;
    },
    fetchDetailSuccess(state, action: PayloadAction<LessonDetailResponse>) {
      state.lessonDetail = action.payload;
      state.loading = false;
    },
    fetchDetailFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
    fetchTranslatedSentence(
      state,
      action: PayloadAction<{ lessonId: string; languageId: string; language: string }>,
    ) {
      state.translate = {
        lesson_id: action.payload.lessonId,
        language_id: action.payload.languageId,
        language: action.payload.language,
        sentence: [],
        loading: true,
        error: undefined,
      };
    },
    fetchTranslatedSentenceSuccess(state, action: PayloadAction<TranslatedSentenceResponse[]>) {
      state.translate = {
        ...state.translate,
        sentence: action.payload,
        error: undefined,
        loading: false,
      };
    },
    fetchTranslatedSentenceFailure(state, action: PayloadAction<ErrorResponse>) {
      state.translate = {
        ...state.translate,
        sentence: [],
        error: action.payload,
        loading: false,
      };
    },
    setLanguageList(state, action: PayloadAction<LanguageResponse[]>) {
      state.languageList = action.payload;
    },
    generateTranslatedSentence(
      state,
      action: PayloadAction<{ sentenceId: string; languageId: string }>,
    ) {
      state.translate.individualLoading = true;
      state.translate.individualError = undefined;
    },
    generateTranslatedSentenceSuccess(state, action: PayloadAction<TranslatedSentenceResponse>) {
      state.translate.sentence = [...state.translate.sentence, action.payload];
      state.translate.individualLoading = false;
    },
    generateTranslatedSentenceFailure(state, action: PayloadAction<ErrorResponse>) {
      state.translate.individualError = action.payload;
      state.translate.individualLoading = false;
    },
    setAudioLoading(state, action: PayloadAction<boolean>) {
      state.translate.audioLoading = action.payload;
    },
  },
});

export const { name, actions, reducer } = LessonSlice;
