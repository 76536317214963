import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorResponse,
  PaginationMetaResponse,
  PaginationResponse,
  SituationGroupResponse,
} from '../../../utilities/apiUtilities';

export type SituationGroupState = {
  data: SituationGroupResponse[];
  loading: boolean;
  paginationLoading: boolean;
  error?: ErrorResponse;
  meta?: PaginationMetaResponse;
  situationGroupDetail?: SituationGroupResponse;
};

export const initialState: SituationGroupState = {
  data: [],
  loading: false,
  paginationLoading: false,
};

export const SituationGroupSlice = createSlice({
  name: 'situationGroup',
  initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ size: number; page: number }>) {
      if (action.payload.page > 1) {
        state.paginationLoading = true;
      } else {
        state.loading = true;
      }
      state.error = undefined;
      state.data = [];
    },
    fetchSuccess(state, action: PayloadAction<PaginationResponse<SituationGroupResponse>>) {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.data = [];
      state.meta = undefined;
      state.error = action.payload;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchDetail(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
      state.error = undefined;
      state.situationGroupDetail = undefined;
    },
    fetchDetailSuccess(state, action: PayloadAction<SituationGroupResponse>) {
      state.situationGroupDetail = action.payload;
      state.loading = false;
    },
    fetchDetailFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { name, actions, reducer } = SituationGroupSlice;
