import { Button, DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import { SituationGroupResponse } from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { deletedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface SituationGroupDeleteModalProps {
  situationGroup: SituationGroupResponse;
}

export interface SituationGroupDeleteModalRef {
  open: () => void;
  close: () => void;
}

const SituationGroupDeleteModal = forwardRef(
  (props: SituationGroupDeleteModalProps, ref: Ref<SituationGroupDeleteModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onClick = async () => {
      await api.deleteSituationGroup(props.situationGroup.id);
      handleClose();
      dispatch(
        alertActions.setAlert(deletedMessage('Situation Group', props.situationGroup.title)),
      );
      navigate('/situation_group');
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <ModalDialogTitle onClose={handleClose} id="delete-modal-title">
          DELETE SITUATION GROUP
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <Typography fontStyle={'italic'} gutterBottom>
              Are you sure you want to delete this situation group?
            </Typography>
            <Typography gutterBottom>Title: {props.situationGroup.title}</Typography>
            <Typography gutterBottom>Description: {props.situationGroup.description}</Typography>
            <Typography gutterBottom>
              Image: {props.situationGroup?.image?.original_name}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onClick}>Delete</Button>
        </DialogActions>
      </ModalDialog>
    );
  },
);

export default SituationGroupDeleteModal;
