import { alpha, Box, Skeleton, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ErrorResponse } from '../utilities/apiUtilities';

const useStyles = makeStyles()((theme: Theme) => ({
  error: {
    display: 'flex',
    backgroundColor: alpha(theme.palette.text.primary, 0.11),
    height: '400px',
    width: '100%',
    alignItems: 'flex-start',
  },
}));

interface LoadingSectionProps {
  error?: ErrorResponse;
}

const LoadingSection = ({ error }: LoadingSectionProps): JSX.Element => {
  const { classes } = useStyles();

  if (error != null) {
    return (
      <Box className={classes.error}>
        <Box>Error occred while loading data.</Box>
      </Box>
    );
  }

  return <Skeleton variant="rectangular" width="100%" height={400} animation="wave" />;
};

export default LoadingSection;
