import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { SentenceTypeResponse } from '../../utilities/apiUtilities';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

export interface SentenceTypeAddModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
};

const SentenceTypeAddModal = forwardRef(({}, ref: Ref<SentenceTypeAddModalRef>) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultValues: FormProps = {
    title: '',
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSuccess = async (values: FormProps) => {
    const response = await api
      .postSentenceType<SentenceTypeResponse>(values)
      .then((res) => res)
      .catch((err) => undefined);

    if (response) {
      dispatch(alertActions.setAlert(createdMessage('Sentence Type', response.title)));
      navigate('/sentence_type/' + response.id);
    } else {
      dispatch(alertActions.setAlert(errorCreatedMessage('Sentence Type', values.title)));
      handleClose();
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <ModalDialog open={open} onClose={handleClose}>
      <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
        <ModalDialogTitle onClose={handleClose} id="add-modal-title">
          ADD SENTENCE TYPE
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <TextFieldElement name={'title'} label={'Title'} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type={'submit'}>Save</Button>
        </DialogActions>
      </FormContainer>
    </ModalDialog>
  );
});

export default SentenceTypeAddModal;
