import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  PaginationResponse,
  SentenceResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './SentenceSlice';

export function* fetchSentenceList(
  action: PayloadAction<{ size: number; page: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<SentenceResponse> = yield call(api.getSentenceList, size, page);
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchSentenceId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: SentenceResponse = yield call(api.getSentenceById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* SentenceSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchSentenceList),
    yield takeEvery(actions.fetchDetail, fetchSentenceId),
  ]);
}
