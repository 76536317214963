import { ErrorResponse, API_PREFIX } from '../utilities/apiUtilities';
import { authHeader } from '../utilities/utils';

export class API {
  baseUrl: string;

  constructor(private url: string) {
    this.baseUrl = url;
  }

  fetchApi = async <R>(uri: string, method: string, payload?: string): Promise<R> => {
    const request: RequestInit = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authHeader() as HeadersInit),
      },
    };
    if (payload) {
      request.body = payload;
    }
    return fetch(`${this.baseUrl}${uri}`, request)
      .then((res) => {
        if (res.status === 204) {
          return Promise.resolve();
        }
        if (res.ok) {
          return res.json();
        }
        return Promise.reject(res);
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw error;
        }
        const basicError: ErrorResponse = {
          status: error.status,
          title: error.statusText,
        };
        return error.text().then((text: string) => {
          try {
            const json: ErrorResponse = JSON.parse(text);
            return Promise.reject(json);
          } catch (err) {
            return Promise.reject(basicError);
          }
        });
      });
  };

  fetchApiWithFormData = async <R>(
    uri: string,
    method: string,
    formData?: FormData,
  ): Promise<R> => {
    const request: RequestInit = {
      method,
      headers: {
        Accept: 'application/json',
        ...(authHeader() as HeadersInit),
      },
      body: formData,
    };

    return fetch(`${this.baseUrl}${uri}`, request)
      .then((res) => {
        if (res.status === 204) {
          return Promise.resolve();
        }
        if (res.ok) {
          return res.json();
        }
        return Promise.reject(res);
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw error;
        }
        const basicError: ErrorResponse = {
          status: error.status,
          title: error.statusText,
        };
        return error.text().then((text: string) => {
          try {
            const json: ErrorResponse = JSON.parse(text);
            return Promise.reject(json);
          } catch (err) {
            return Promise.reject(basicError);
          }
        });
      });
  };

  fetchBlob = async (uri: string, method: string, payload?: string): Promise<Blob> => {
    const request: RequestInit = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authHeader() as HeadersInit),
      },
    };
    if (payload) {
      request.body = payload;
    }
    return fetch(`${this.baseUrl}${uri}`, request)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }
        return Promise.reject(res);
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw error;
        }
        const basicError: ErrorResponse = {
          status: error.status,
          title: error.statusText,
        };
        return error.text().then((text: string) => {
          try {
            const json: ErrorResponse = JSON.parse(text);
            return Promise.reject(json);
          } catch (err) {
            return Promise.reject(basicError);
          }
        });
      });
  };

  post = async <R>(uri: string, payload: string): Promise<R> => {
    return this.fetchApi(uri, 'POST', payload);
  };

  put = async <R>(uri: string, payload?: string): Promise<R> => {
    return this.fetchApi(uri, 'PUT', payload);
  };

  get = async <R>(uri: string): Promise<R> => {
    return this.fetchApi(uri, 'GET');
  };

  remove = async <R>(uri: string): Promise<R> => {
    return this.fetchApi(uri, 'DELETE');
  };

  getUserProfile = async <R>(): Promise<R> => {
    return this.get(`${API_PREFIX}/auth/user-profile`);
  };

  login = async <R>(payload: { email: string; password: string }): Promise<R> => {
    return this.post(`${API_PREFIX}/auth/login`, JSON.stringify(payload));
  };

  logout = async <R>(): Promise<R> => {
    return this.post(`${API_PREFIX}/auth/logout`, '');
  };

  getOverview = async <R>(): Promise<R> => {
    return this.get(`${API_PREFIX}/adminoverview`);
  };

  getLanguageList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/language?${queryString}`);
  };

  getLanguageById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/language/${id}`);
  };

  getPublishedLanguage = async <R>(): Promise<R> => {
    return this.get(`${API_PREFIX}/language/published`);
  };

  getPublishedLanguageExcluding = async <R>(language_code: string): Promise<R> => {
    return this.get(`${API_PREFIX}/language/published/exclude/${language_code}`);
  };

  postLanguage = async <R>(formData: FormData): Promise<R> => {
    return this.fetchApiWithFormData(`${API_PREFIX}/language`, 'POST', formData);
  };

  putLanguage = async <R>(id: string, formData: FormData): Promise<R> => {
    return this.fetchApiWithFormData(`${API_PREFIX}/language/${id}`, 'POST', formData);
  };

  deleteLanguage = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/language/${id}`);
  };

  getLessonList = async <R>(size: number, page: number, lesson_group_id?: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    if (lesson_group_id) {
      queryString.append('lesson_group_id', String(lesson_group_id));
    }

    return this.get(`${API_PREFIX}/lesson?${queryString}`);
  };

  getLessonById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/lesson/${id}/details`);
  };

  postLesson = async <R>(payload: {
    title: string;
    master: number;
    lesson_group_id: number;
    published: number;
  }): Promise<R> => {
    return this.post(`${API_PREFIX}/lesson`, JSON.stringify(payload));
  };

  putLesson = async <R>(
    id: string,
    payload: {
      title: string;
      lesson_group_id: number;
      published: number;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/lesson/${id}`, JSON.stringify(payload));
  };

  deleteLesson = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/lesson/${id}`);
  };

  getLessonGroupList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/lessongroup?${queryString}`);
  };

  getLessonGroupById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/lessongroup/${id}`);
  };

  postLessonGroup = async <R>(payload: {
    title: string;
    level_id: number;
    situation_id: number;
  }): Promise<R> => {
    return this.post(`${API_PREFIX}/lessongroup`, JSON.stringify(payload));
  };

  putLessonGroup = async <R>(
    id: string,
    payload: {
      title: string;
      level_id: number;
      situation_id: number;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/lessongroup/${id}`, JSON.stringify(payload));
  };

  deleteLessonGroup = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/lessongroup/${id}`);
  };

  postLessonGroupCreateLesson = async <R>(
    id: string,
    payload: {
      title: string;
      lesson_group_id: number;
      published: number;
    },
  ): Promise<R> => {
    return this.post(`${API_PREFIX}/lessongroup/${id}/createlesson`, JSON.stringify(payload));
  };

  getLessonTemplatePhraseGroupList = async <R>(lesson_group_id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/lessongroup/${lesson_group_id}/lessontemplatephrasegroup`);
  };

  postLessonTemplatePhraseGroup = async <R>(
    lesson_group_id: string,
    payload: {
      name: string;
      phrase_group_id: number;
    },
  ): Promise<R> => {
    return this.post(
      `${API_PREFIX}/lessongroup/${lesson_group_id}/lessontemplatephrasegroup`,
      JSON.stringify(payload),
    );
  };

  deleteLessonTemplatePhraseGroup = async <R>(lesson_group_id: string, id: string): Promise<R> => {
    return this.remove(
      `${API_PREFIX}/lessongroup/${lesson_group_id}/lessontemplatephrasegroup/${id}`,
    );
  };

  getLevelList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/level?${queryString}`);
  };

  getLevelById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/level/${id}`);
  };

  postLevel = async <R>(payload: {
    title: string;
    description?: string;
    published: number;
  }): Promise<R> => {
    return this.post(`${API_PREFIX}/level`, JSON.stringify(payload));
  };

  putLevel = async <R>(
    id: string,
    payload: {
      title: string;
      description?: string;
      published: number;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/level/${id}`, JSON.stringify(payload));
  };

  deleteLevel = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/level/${id}`);
  };

  getSituationList = async <R>(
    size: number,
    page: number,
    situation_group_id?: number,
  ): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    if (situation_group_id) {
      queryString.append('situation_group_id', String(situation_group_id));
    }

    return this.get(`${API_PREFIX}/situation?${queryString}`);
  };

  getSituationById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/situation/${id}`);
  };

  postSituation = async <R>(formData: FormData): Promise<R> => {
    return this.fetchApiWithFormData(`${API_PREFIX}/situation`, 'POST', formData);
  };

  putSituation = async <R>(id: string, formData: FormData): Promise<R> => {
    return this.fetchApiWithFormData(`${API_PREFIX}/situation/${id}`, 'POST', formData);
  };

  deleteSituation = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/situation/${id}`);
  };

  getSituationGroupList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/situationgroup?${queryString}`);
  };

  getSituationGroupById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/situationgroup/${id}`);
  };

  postSituationGroup = async <R>(formData: FormData): Promise<R> => {
    return this.fetchApiWithFormData(`${API_PREFIX}/situationgroup`, 'POST', formData);
  };

  putSituationGroup = async <R>(id: string, formData: FormData): Promise<R> => {
    return this.fetchApiWithFormData(`${API_PREFIX}/situationgroup/${id}`, 'POST', formData);
  };

  deleteSituationGroup = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/situationgroup/${id}`);
  };

  generateTranslatedSentence = async <R>(sentence_id: string, language_id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/sentence/${sentence_id}/translate/${language_id}`);
  };

  getTranslatedSentence = async <R>(lesson_id: string, language_id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/sentence/${lesson_id}/translated/${language_id}`);
  };

  getTranslatedSentenceAudio = async (id: string): Promise<Blob> => {
    return this.fetchBlob(`${API_PREFIX}/translated_sentence/${id}/audio`, 'GET');
  };

  getQuestionByLessonGroup = async <R>(lesson_group_id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/question/lessongroup/${lesson_group_id}`);
  };

  getOneQuestionByLessonGroup = async <R>(
    question_id: string,
    lesson_group_id: string,
  ): Promise<R> => {
    return this.get(`${API_PREFIX}/question/${question_id}/lessongroup/${lesson_group_id}`);
  };

  generateSentenceTemplatePhraseGroup = async <R>(
    question_id: string,
    lesson_group_id: string,
  ): Promise<R> => {
    return this.get(
      `${API_PREFIX}/question/${question_id}/lessongroup/${lesson_group_id}/generate`,
    );
  };

  getQuestionList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/question?${queryString}`);
  };

  getQuestionById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/question/${id}`);
  };

  postQuestion = async <R>(payload: {
    lesson_group_id: number;
    sentence_type_id: number;
    gtp_prompt?: string;
  }): Promise<R> => {
    return this.post(`${API_PREFIX}/question`, JSON.stringify(payload));
  };

  putQuestion = async <R>(
    id: string,
    payload: {
      lesson_group_id: string;
      sentence_type_id: string;
      gtp_prompt?: string;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/question/${id}`, JSON.stringify(payload));
  };

  deleteQuestion = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/question/${id}`);
  };

  getPhraseList = async <R>(size: number, page: number, phrase_group_id?: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    if (phrase_group_id) {
      queryString.append('phrase_group_id', String(phrase_group_id));
    }

    return this.get(`${API_PREFIX}/phrase?${queryString}`);
  };

  getPhraseById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/phrase/${id}`);
  };

  postPhrase = async <R>(payload: { title: string; phrase_group_id: number }): Promise<R> => {
    return this.post(`${API_PREFIX}/phrase`, JSON.stringify(payload));
  };

  putPhrase = async <R>(
    id: string,
    payload: {
      title: string;
      phrase_group_id: number;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/phrase/${id}`, JSON.stringify(payload));
  };

  deletePhrase = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/phrase/${id}`);
  };

  getPhraseGroupList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/phrasegroup?${queryString}`);
  };

  getPhraseGroupById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/phrasegroup/${id}`);
  };

  postPhraseGroup = async <R>(payload: { title: string }): Promise<R> => {
    return this.post(`${API_PREFIX}/phrasegroup`, JSON.stringify(payload));
  };

  putPhraseGroup = async <R>(
    id: string,
    payload: {
      title: string;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/phrasegroup/${id}`, JSON.stringify(payload));
  };

  deletePhraseGroup = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/phrasegroup/${id}`);
  };

  getSentenceList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/sentence?${queryString}`);
  };

  getSentenceById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/sentence/${id}`);
  };

  postSentence = async <R>(payload: {
    title: string;
    sentence_template_id: number;
    lesson_id: number;
    language_id: number;
    question_id: number;
  }): Promise<R> => {
    return this.post(`${API_PREFIX}/sentence`, JSON.stringify(payload));
  };

  putSentence = async <R>(
    id: string,
    payload: {
      title: string;
      sentence_template_id: string;
      lesson_id: string;
      language_id: string;
      question_id: number;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/sentence/${id}`, JSON.stringify(payload));
  };

  deleteSentence = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/sentence/${id}`);
  };

  getSentenceTemplateList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/sentencetemplate?${queryString}`);
  };

  getSentenceTemplateById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/sentencetemplate/${id}`);
  };

  postSentenceTemplate = async <R>(payload: {
    title: string;
    number: number;
    question_id: number;
  }): Promise<R> => {
    return this.post(`${API_PREFIX}/sentencetemplate`, JSON.stringify(payload));
  };

  putSentenceTemplate = async <R>(
    id: string,
    payload: {
      title: string;
      number: number;
      question_id: number;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/sentencetemplate/${id}`, JSON.stringify(payload));
  };

  deleteSentenceTemplate = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/sentencetemplate/${id}`);
  };

  getSentenceTypeList = async <R>(size: number, page: number): Promise<R> => {
    const queryString = new URLSearchParams({
      size: String(size),
      page: String(page),
    });

    return this.get(`${API_PREFIX}/sentencetype?${queryString}`);
  };

  getSentenceTypeById = async <R>(id: string): Promise<R> => {
    return this.get(`${API_PREFIX}/sentencetype/${id}`);
  };

  postSentenceType = async <R>(payload: { title: string }): Promise<R> => {
    return this.post(`${API_PREFIX}/sentencetype`, JSON.stringify(payload));
  };

  putSentenceType = async <R>(
    id: string,
    payload: {
      title: string;
    },
  ): Promise<R> => {
    return this.put(`${API_PREFIX}/sentencetype/${id}`, JSON.stringify(payload));
  };

  deleteSentenceType = async <R>(id: string): Promise<R> => {
    return this.remove(`${API_PREFIX}/sentencetype/${id}`);
  };

  getImage = async (id: string): Promise<Blob> => {
    return this.fetchBlob(`${API_PREFIX}/image/${id}`, 'GET');
  };
}

export default new API('');
