import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import { LessonState, actions } from './modules/LessonSlice';
import LoadingSection from '../../components/LoadingSection';
import { selectLessonData } from './modules/LessonSelector';
import LessonList from './LessonList';
import LessonAddModal, { LessonAddModalRef } from './LessonAddModal';
import { defaultPagination } from '../../utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

function LessonPage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const lessons: LessonState = useSelector(selectLessonData, shallowEqual);
  const [showLoading, setShowLoading] = useState(true);
  const addRef = useRef<LessonAddModalRef>(null);
  const params = useParams();

  const fetchData = (
    size = lessons.meta?.per_page || defaultPagination.size,
    page = lessons?.meta?.current_page || defaultPagination.page,
    lesson_group_id = 0,
  ) => {
    return dispatch(actions.fetch({ size, page, lesson_group_id }));
  };

  useEffect(() => {
    if (!lessons?.loading) {
      const size = Number(searchParams.get('size')) || defaultPagination.size;
      const page = Number(searchParams.get('page')) || defaultPagination.page;
      const lessonGroupId = Number(params.lesson_group_id);
      fetchData(size, page, lessonGroupId);
    }
  }, [location, searchParams]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowLessonList = () => {
    if (showLoading || lessons.loading || lessons.error) {
      return <LoadingSection error={lessons.error} />;
    }
    return <LessonList state={lessons} setSearchParams={setSearchParams} />;
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/lesson_group', name: 'Lesson Group' },
                  {
                    path: '/lesson_group/' + params.lesson_group_id,
                    name: params.lesson_group_id || 'Unknown',
                  },
                  { path: '/lesson_group/' + params.lesson_group_id + '/lesson', name: 'Lesson' },
                ]}
              />
            </Box>
            <Box data-testid="alerts-refresh-button">
              <Button
                onClick={() => {
                  if (addRef.current) addRef.current.open();
                }}
                startIcon={<Add />}
                color="primary"
                variant="outlined"
              >
                Add Lesson
              </Button>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {!(showLoading || lessons.loading || lessons.error) && (
              <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {lessons?.meta?.total || 0} Lesson{(lessons?.meta?.total || 0) > 1 ? 's' : ''}
              </Typography>
            )}
            <ShowLessonList />
          </Paper>
        </Grid>
      </Grid>
      <LessonAddModal
        ref={addRef}
        lesson_group_id={params.lesson_group_id ? parseInt(params.lesson_group_id) : 0}
      />
    </div>
  );
}

export default LessonPage;
