import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponse, UserResponse } from '../../../utilities/apiUtilities';
import { removeAccessToken } from '../../../utilities/utils';

export type UserProfileState = {
  profile?: UserResponse;
  loading: boolean;
  error?: ErrorResponse;
};

export const initialState: UserProfileState = {
  loading: true,
};

export const UserProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    fetch(state) {
      state.loading = true;
      state.error = undefined;
      state.profile = undefined;
    },
    fetchSuccess(state, action: PayloadAction<UserResponse>) {
      state.profile = action.payload;
      state.loading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
    clearFetch(state) {
      state.loading = true;
    },
    clearSuccess(state) {
      removeAccessToken();
      state.profile = undefined;
      state.loading = false;
      state.error = undefined;
    },
    clearFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { name, actions, reducer } = UserProfileSlice;
