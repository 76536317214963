import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

function ResetPassword() {
  const { classes } = useStyles();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {};

  return (
    <Container component="main" maxWidth="xs">
      <Box className={classes.root}>
        <Typography component="h1" variant="h5">
          Reset your account password
        </Typography>
        <Typography component="h5" variant="h5">
          test@gmail.com
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="new-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirm Password"
            type="password"
            id="password"
            autoComplete="new-password"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Reset password
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default ResetPassword;
