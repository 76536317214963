import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { LessonTemplateState } from './LessonTemplateSlice';

const LessonTemplateData = (state: RootState): LessonTemplateState => state.lessonTemplate;

export const selectLessonTemplateData: Selector<RootState, LessonTemplateState> = createSelector(
  LessonTemplateData,
  (lessonTemplate) => lessonTemplate,
);
