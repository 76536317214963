import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import {
  PaginationResponse,
  PhraseGroupResponse,
  PhraseResponse,
} from '../../utilities/apiUtilities';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface PhraseAddModalProps {
  phraseGroupId?: number;
}

export interface PhraseAddModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  phrase_group_id: number;
};

const PhraseAddModal = forwardRef(
  ({ phraseGroupId }: PhraseAddModalProps, ref: Ref<PhraseAddModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phraseGroupOptions, setPhraseGroupOptions] = useState<{ id: string; label: string }[]>(
      [],
    );

    const defaultValues: FormProps = {
      title: '',
      phrase_group_id: phraseGroupId || 0,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .postPhrase<PhraseResponse>(values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(alertActions.setAlert(createdMessage('Phrase', response.title)));
        navigate('/phrase_group/' + response.phrase_group_id);
      } else {
        dispatch(alertActions.setAlert(errorCreatedMessage('Phrase', values.title)));
        handleClose();
      }
    };

    const formatList = (list: PhraseGroupResponse[]) => {
      return list.map((item) => {
        return { id: item.id, label: item.title || 'Unknown' };
      });
    };

    useEffect(() => {
      (async () => {
        if (phraseGroupId !== undefined) {
          const phraseGroup = await api
            .getPhraseGroupById<PhraseGroupResponse>(phraseGroupId.toString())
            .then((res) => formatList([res]))
            .catch((err) => []);
          setPhraseGroupOptions(phraseGroup);
        } else {
          const size = 100;
          const page = 0;
          const phraseGroups = await api
            .getPhraseGroupList<PaginationResponse<PhraseGroupResponse>>(size, page)
            .then((res) => formatList(res.data))
            .catch((err) => []);
          setPhraseGroupOptions(phraseGroups);
        }
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="add-modal-title">
            ADD PHRASE
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
              <SelectElement
                name={'phrase_group_id'}
                label={'Phrase Group'}
                options={phraseGroupOptions}
                fullWidth
                disabled={phraseGroupId !== undefined}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default PhraseAddModal;
