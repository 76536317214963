import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { LevelState } from './LevelSlice';

const LevelData = (state: RootState): LevelState => state.level;

export const selectLevelData: Selector<RootState, LevelState> = createSelector(
  LevelData,
  (level) => level,
);

export const selectLevelDetail = createSelector(LevelData, (level) => level?.levelDetail);
