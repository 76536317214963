import { Avatar, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { actions } from '../modules/auth/modules/UserProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../modules/auth/modules/UserProfileSelector';

const drawerWidth = 240;

interface HeaderProps {
  open: boolean;
  toggleDrawer: () => void;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({ open, toggleDrawer }: HeaderProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const signOut = () => {
    dispatch(actions.clearFetch());
    navigate('/login');
  };

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {!open ? 'Lingobricks Admin' : ''}
        </Typography>
        <IconButton color="inherit" disableRipple>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 22, height: 22 }}></Avatar>
          <Typography variant="body1">{user?.name || 'My Account'}</Typography>
        </IconButton>
        <IconButton color="inherit" onClick={signOut} disableRipple>
          <Typography variant="body1">Sign out</Typography>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
