import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Button } from '@mui/material';

import { SentenceResponse, TranslatedSentenceResponse } from '../../../utilities/apiUtilities';
import api from '../../../services/api.service';
import { LessonQuestionTranslate, actions } from '../modules/LessonSlice';

interface LessionDetailQuestionCellProps {
  sentence: TranslatedSentenceResponse | undefined;
  innerItem: SentenceResponse;
  translate: LessonQuestionTranslate;
}

const LessionDetailQuestionCell = ({
  sentence,
  innerItem,
  translate,
}: LessionDetailQuestionCellProps): JSX.Element => {
  const dispatch = useDispatch();
  const [audioLoading, setAudioLoading] = useState(false);

  const translateSentence = (sentence_id: string, language_id: string) => {
    return dispatch(
      actions.generateTranslatedSentence({
        sentenceId: sentence_id,
        languageId: language_id,
      }),
    );
  };

  const playAudio = async (translated_sentence: TranslatedSentenceResponse) => {
    if (audioLoading) {
      return;
    }

    setAudioLoading(true);

    const response = await api.getTranslatedSentenceAudio(translated_sentence.id);
    if (response) {
      const url = URL.createObjectURL(response);
      const audio = new Audio(url);
      audio.play();
    }

    setAudioLoading(false);
  };

  return (
    <Fragment>
      {sentence !== undefined ? (
        <Box display="flex">
          {sentence.title}
          <Button
            size="small"
            sx={{ padding: '0px' }}
            disabled={audioLoading}
            onClick={() => playAudio(sentence)}
          >
            <VolumeUpIcon />
          </Button>
        </Box>
      ) : (
        <Button
          size="small"
          sx={{ padding: '0px' }}
          disabled={translate?.individualLoading}
          onClick={() => translateSentence(innerItem.id || '', translate?.language_id || '')}
        >
          Generate Translation
        </Button>
      )}
    </Fragment>
  );
};

export default LessionDetailQuestionCell;
