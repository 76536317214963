import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorResponse,
  PaginationMetaResponse,
  PaginationResponse,
  PhraseResponse,
} from '../../../utilities/apiUtilities';

export type PhraseState = {
  data: PhraseResponse[];
  loading: boolean;
  paginationLoading: boolean;
  error?: ErrorResponse;
  meta?: PaginationMetaResponse;
  phraseDetail?: PhraseResponse;
};

export const initialState: PhraseState = {
  data: [],
  loading: false,
  paginationLoading: false,
};

export const PhraseSlice = createSlice({
  name: 'phrase',
  initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ size: number; page: number; phrase_group_id?: number }>) {
      if (action.payload.page > 1) {
        state.paginationLoading = true;
      } else {
        state.loading = true;
      }
      state.error = undefined;
      state.data = [];
    },
    fetchSuccess(state, action: PayloadAction<PaginationResponse<PhraseResponse>>) {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.data = [];
      state.meta = undefined;
      state.error = action.payload;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchDetail(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
      state.error = undefined;
      state.phraseDetail = undefined;
    },
    fetchDetailSuccess(state, action: PayloadAction<PhraseResponse>) {
      state.phraseDetail = action.payload;
      state.loading = false;
    },
    fetchDetailFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { name, actions, reducer } = PhraseSlice;
