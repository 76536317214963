import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../../../components/ModalDialog';
import { SentenceTemplateResponse } from '../../../../utilities/apiUtilities';
import { actions as alertActions } from '../../../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../../../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

export interface SentenceTemplateAddModalRef {
  open: () => void;
  close: () => void;
}

interface SentenceTemplateAddModalProps {
  question_id: number;
  onSuccessCallback: (sentence_template: SentenceTemplateResponse) => void;
  onCloseCallback?: () => void;
}

type FormProps = {
  title: string;
  number: number;
  question_id: number;
};

const SentenceTemplateAddModal = forwardRef(
  (props: SentenceTemplateAddModalProps, ref: Ref<SentenceTemplateAddModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [questionOptions] = useState<{ id: string; label: string }[]>([
      { id: props.question_id.toString(), label: `Question #${props.question_id}` },
    ]);

    const defaultValues: FormProps = {
      title: '',
      number: 0,
      question_id: props.question_id,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);

      if (props.onCloseCallback) {
        props.onCloseCallback();
      }
    };

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .postSentenceTemplate<SentenceTemplateResponse>(values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(alertActions.setAlert(createdMessage('Sentence Template', response.title)));
        props.onSuccessCallback(response);
      } else {
        dispatch(alertActions.setAlert(errorCreatedMessage('Sentence Template', values.title)));
        handleClose();
      }
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="add-modal-title">
            ADD SENTENCE TEMPLATE
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
              <TextFieldElement name={'number'} label={'Number'} />
              <SelectElement
                name={'question_id'}
                label={'Question ID'}
                options={questionOptions}
                fullWidth
                disabled
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default SentenceTemplateAddModal;
