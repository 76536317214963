import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import {
  PaginationResponse,
  SituationGroupResponse,
  SituationResponse,
} from '../../utilities/apiUtilities';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface SituationAddModalProps {
  situationGroupId?: number;
}

export interface SituationAddModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  situation_group_id: number;
  description?: string;
  image?: string;
};

const SituationAddModal = forwardRef(
  ({ situationGroupId }: SituationAddModalProps, ref: Ref<SituationAddModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [situationGroupOptions, setSituationGroupOptions] = useState<
      { id: string; label: string }[]
    >([]);

    const defaultValues: FormProps = {
      title: '',
      situation_group_id: situationGroupId || 0,
      description: '',
      image: undefined,
    };
    const [imageFile, setImageFile] = useState<File | undefined>(undefined);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps, file: File | undefined) => {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description || '');
      formData.append('situation_group_id', values.situation_group_id.toString());

      if (file) {
        formData.append('image', file);
      }

      const response = await api
        .postSituation<SituationResponse>(formData)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(alertActions.setAlert(createdMessage('Situation', response.title)));
        navigate('/situation/' + response.id);
      } else {
        dispatch(alertActions.setAlert(errorCreatedMessage('Situation', values.title)));
        handleClose();
      }
    };

    const formatList = (list: SituationGroupResponse[]) => {
      return list.map((item) => {
        return { id: item.id, label: item.title || 'Unknown' };
      });
    };

    useEffect(() => {
      (async () => {
        if (situationGroupId !== undefined) {
          const situationGroup = await api
            .getSituationGroupById<SituationGroupResponse>(situationGroupId.toString())
            .then((res) => formatList([res]))
            .catch((err) => []);
          setSituationGroupOptions(situationGroup);
        } else {
          const size = 100;
          const page = 0;
          const situationGroups = await api
            .getSituationGroupList<PaginationResponse<SituationGroupResponse>>(size, page)
            .then((res) => formatList(res.data))
            .catch((err) => []);
          setSituationGroupOptions(situationGroups);
        }
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer
          defaultValues={defaultValues}
          onSuccess={(data) => onSuccess(data, imageFile)}
        >
          <ModalDialogTitle onClose={handleClose} id="add-modal-title">
            ADD SITUATION
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
              <TextFieldElement multiline minRows={3} name={'description'} label={'Description'} />
              <TextFieldElement
                name={'image'}
                label={'Image'}
                type="file"
                onChange={(e) => {
                  setImageFile((e.target as HTMLInputElement)?.files?.[0]);
                }}
              />
              <SelectElement
                name={'situation_group_id'}
                label={'Situation Group'}
                options={situationGroupOptions}
                fullWidth
                disabled={situationGroupId !== undefined}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default SituationAddModal;
