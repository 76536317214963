import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  LessonGroupResponse,
  PaginationResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './LessonGroupSlice';

export function* fetchLessonGroupList(
  action: PayloadAction<{ size: number; page: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<LessonGroupResponse> = yield call(
      api.getLessonGroupList,
      size,
      page,
    );
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchLessonGroupId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: LessonGroupResponse = yield call(api.getLessonGroupById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* LessonGroupSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchLessonGroupList),
    yield takeEvery(actions.fetchDetail, fetchLessonGroupId),
  ]);
}
