import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

function ErrorView() {
  const { classes } = useStyles();

  return (
    <div>
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  );
}

export default ErrorView;
