import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  PaginationResponse,
  QuestionListResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './QuestionSlice';

export function* fetchQuestionList(
  action: PayloadAction<{ size: number; page: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<QuestionListResponse> = yield call(
      api.getQuestionList,
      size,
      page,
    );
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchQuestionId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: QuestionListResponse = yield call(api.getQuestionById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* QuestionSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchQuestionList),
    yield takeEvery(actions.fetchDetail, fetchQuestionId),
  ]);
}
