import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridDeleteIcon,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LessonState, actions } from './modules/LessonSlice';
import { Link, SetURLSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import LessonDeleteModal, { LessonDeleteModalRef } from './LessonDeleteModal';
import { LessonResponse } from '../../utilities/apiUtilities';
import { defaultPagination } from '../../utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
  },
}));

interface LessonListProps {
  state: LessonState;
  setSearchParams: SetURLSearchParams;
}

const LessonList = ({ state, setSearchParams }: LessonListProps): JSX.Element => {
  const { classes } = useStyles();
  const currentSize = useRef(state.meta?.per_page || defaultPagination.size);
  const deleteRef = useRef<LessonDeleteModalRef>(null);
  const [deleteLesson, setDeleteLesson] = useState<LessonResponse | null>(null);

  const paginationFetch = (page: number, pageSize?: number) => {
    let newPage = page <= 1 ? 1 : page;
    if (pageSize !== currentSize.current) {
      newPage = defaultPagination.page;
    }

    if (pageSize) {
      currentSize.current = pageSize;
    }

    setSearchParams(
      `?${new URLSearchParams({ page: newPage.toString(), size: currentSize.current.toString() })}`,
    );
  };

  const handlePaginationChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    if (
      (model.page !== state.meta?.current_page && model.page > 0) ||
      model.pageSize !== state.meta?.per_page
    ) {
      paginationFetch(model.page, model.pageSize);
    }
  };

  const onClickDeleteLesson = (lesson: LessonResponse | null) => {
    setDeleteLesson(lesson);
  };

  useEffect(() => {
    if (deleteLesson && deleteRef.current) {
      deleteRef.current.open();
    }
  }, [deleteLesson]);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={'/lesson_group/' + params.row.lesson_group_id + '/lesson/' + params.row.id}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'lesson_group_id',
      headerName: 'Lesson Group ID',
      flex: 1,
      renderCell: (params) => {
        return <Link to={'/lesson_group/' + params.value}>{params.value}</Link>;
      },
    },
    {
      field: 'published',
      headerName: 'Publish/Draft',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        return params.value === 1 ? 'Published' : 'Draft';
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        return (
          <Button color="warning" onClick={() => onClickDeleteLesson(params.row as LessonResponse)}>
            <GridDeleteIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <DataGrid
        paginationMode="server"
        paginationModel={{
          page: state.meta?.current_page || defaultPagination.page,
          pageSize: state.meta?.per_page || defaultPagination.size,
        }}
        rows={state.data}
        pagination
        columns={columns}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[10, 20, 50, 100]}
        rowCount={state.meta?.total || 100}
        disableColumnMenu
        columnHeaderHeight={44}
        rowHeight={100}
        autoHeight
        columnBuffer={columns.length}
        loading={state.paginationLoading}
      />
      {deleteLesson && (
        <LessonDeleteModal
          ref={deleteRef}
          lesson={deleteLesson}
          closeCallback={() => onClickDeleteLesson(null)}
        />
      )}
    </div>
  );
};

export default LessonList;
