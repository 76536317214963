export const API_PREFIX =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '/api';

export interface ErrorDetail {
  errorCode: string;
  message: string;
  context?: { [key: string]: string };
}

export interface ErrorResponse {
  status: number;
  title: string;
  correlationId?: string;
  errors?: ErrorDetail[];
}

export interface UserResponse {
  id: number;
  name: string;
  email: string;
  email_verified_at: string | null;
  remember_token: string | null;
  created_at: string;
  updated_at: string;
}

export interface LoginResponse {
  access_token: string;
  token_type: string;
  user: UserResponse;
  expires_in: number;
}

export interface OverviewResponse {
  num_languages: number;
  num_lesson_groups: number;
  num_situation_groups: number;
  num_situations: number;
}

export interface LanguageResponse {
  id: string;
  title: string;
  description?: string;
  image?: ImageResponse;
  published: number;
  created_at: string;
  updated_at: string;
}

export interface LevelResponse {
  id: string;
  title: string;
  description?: string;
  published: number;
  created_at: string;
  updated_at: string;
}

export interface LessonTemplatePhraseGroupResponse {
  id: string;
  phrase_group_id: string;
  phrase_group: PhraseGroupResponse;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface LessonGroupResponse {
  id: string;
  title: string;
  level_id: number;
  situation_id: number;
  number_of_lessons: number;
  level: LevelResponse;
  situation: SituationResponse;
  created_at: string;
  updated_at: string;
}

export interface LessonResponse {
  id: string;
  title: string;
  lesson_group_id: number;
  published: number;
  created_at: string;
  updated_at: string;
}

export interface SentenceResponse {
  id: string;
  title: string;
  language_id: string;
  sentence_template_id: string;
  lesson_id: string;
  audio?: string;
  question_id: number;
  created_at: string;
  updated_at: string;
}

export interface SentenceTemplateResponse {
  id: string;
  title: string;
  question_id: number;
  number: number;
  created_at: string;
  updated_at: string;
}

export interface SentenceTypeResponse {
  id: string;
  title: string;
  created_at: string;
  updated_at: string;
}

export interface QuestionResponse {
  question_id: string;
  sentences: SentenceResponse[];
  sentence_templates: SentenceTemplateResponse[];
}

export interface QuestionListResponse {
  id: string;
  lesson_group_id: string;
  sentence_type_id: string;
  lesson_group: LessonGroupResponse;
  sentence_type: SentenceTypeResponse;
  sentence_template: SentenceTemplateResponse[];
  generate_sentence: SentenceTemplatePhraseGenerateResponse[];
  gpt_prompt: string;
  created_at: string;
  updated_at: string;
}

export interface LessonDetailResponse extends LessonResponse {
  questions: QuestionResponse[];
  lesson_id: string;
}

export interface SituationResponse {
  id: string;
  title: string;
  situation_group_id: number;
  situation_group: SituationGroupResponse;
  description?: string;
  image?: ImageResponse;
  created_at: string;
  updated_at: string;
}

export interface SituationGroupResponse {
  id: string;
  title: string;
  description?: string;
  image?: ImageResponse;
  created_at: string;
  updated_at: string;
}

export interface PhraseGroupResponse {
  id: string;
  title: string;
  created_at: string;
  updated_at: string;
}

export interface PhraseResponse {
  id: string;
  title: string;
  phrase_group_id: number;
  created_at: string;
  updated_at: string;
}

export interface SentenceTemplateResponse {
  id: string;
  title: string;
  number: number;
  created_at: string;
  updated_at: string;
}

export interface SentenceTemplatePhraseGenerateResponse {
  phrase_group_id: number;
  phrase_id: number;
  sentence_template_id: number;
  language_id: number;
  template: string;
  sentence: string;
}

export interface SentenceTemplatePhraseGroupResponse {
  id: string;
  sentence_template_id: number;
  phrase_group_id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface TranslatedSentenceResponse {
  id: string;
  title: string;
  language_id: string;
  sentence_id: string;
  audio?: string;
}

export interface ImageResponse {
  id: string;
  original_name: string;
  file_path: string;
  created_at: string;
  updated_at: string;
}

export interface PaginationMetaResponse {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface PaginationResponse<T> {
  data: T[];
  meta: PaginationMetaResponse;
}
