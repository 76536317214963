import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  LessonTemplatePhraseGroupResponse,
  PaginationResponse,
  QuestionListResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './LessonTemplateSlice';

export function* fetchLessonTemplateQuestionList(
  action: PayloadAction<{ lesson_group_id: string }>,
): SagaIterator {
  const params = action.payload;
  try {
    const res: QuestionListResponse[] = yield call(
      api.getQuestionByLessonGroup,
      params.lesson_group_id,
    );
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchLessonTemplateOneQuestion(
  action: PayloadAction<{ lesson_group_id: string; question_id: string }>,
): SagaIterator {
  const params = action.payload;
  try {
    const res: QuestionListResponse = yield call(
      api.getOneQuestionByLessonGroup,
      params.question_id,
      params.lesson_group_id,
    );
    yield put(actions.fetchOneQuestionSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchOneQuestionFailure(res));
  }
}

export function* fetchLessonTemplatePhraseGroupList(
  action: PayloadAction<{ lesson_group_id: string }>,
): SagaIterator {
  const params = action.payload;
  try {
    const res: PaginationResponse<LessonTemplatePhraseGroupResponse> = yield call(
      api.getLessonTemplatePhraseGroupList,
      params.lesson_group_id,
    );
    yield put(actions.fetchLessonTemplateSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchLessonTemplateFailure(res));
  }
}

export default function* LessonTemplateSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchLessonTemplateQuestionList),
    yield takeEvery(actions.fetchLessonTemplate, fetchLessonTemplatePhraseGroupList),
    yield takeEvery(actions.fetchOneQuestion, fetchLessonTemplateOneQuestion),
  ]);
}
