import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import {
  PaginationResponse,
  QuestionListResponse,
  SentenceTemplateResponse,
} from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions } from './modules/SentenceTemplateSlice';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { errorUpdateMessage, updatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface SentenceTemplateEditModalProps {
  sentenceTemplate: SentenceTemplateResponse;
}

export interface SentenceTemplateEditModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  number: number;
  question_id: number;
};

const SentenceTemplateEditModal = forwardRef(
  (props: SentenceTemplateEditModalProps, ref: Ref<SentenceTemplateEditModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [questionOptions, setQuestionOptions] = useState<{ id: string; label: string }[]>([]);

    const defaultValues: FormProps = {
      title: props.sentenceTemplate.title,
      number: props.sentenceTemplate.number,
      question_id: props.sentenceTemplate.question_id,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .putSentenceTemplate<SentenceTemplateResponse>(props.sentenceTemplate.id, values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(actions.fetchDetailSuccess(response));
        dispatch(alertActions.setAlert(updatedMessage('Sentence Template', response.title)));
      } else {
        dispatch(alertActions.setAlert(errorUpdateMessage('Sentence Template', values.title)));
      }

      handleClose();
    };

    const formatList = (list: QuestionListResponse[]) => {
      return list.map((item) => {
        return { id: item.id, label: `Question #${item.id}` };
      });
    };

    useEffect(() => {
      (async () => {
        const size = 100;
        const page = 0;
        const questions = await api
          .getQuestionList<PaginationResponse<QuestionListResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);

        setQuestionOptions(questions);
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="edit-modal-title">
            EDIT SENTENCE TEMPLATE
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
              <TextFieldElement name={'number'} label={'Number'} />
              <SelectElement
                name={'question_id'}
                label={'Question ID'}
                options={questionOptions}
                fullWidth
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default SentenceTemplateEditModal;
