import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { DataGrid, GridCallbackDetails, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useRef } from 'react';
import { Link, SetURLSearchParams } from 'react-router-dom';

import { PhraseState } from './modules/PhraseSlice';
import { defaultPagination } from '../../utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
  },
}));

interface PhraseListProps {
  state: PhraseState;
  setSearchParams: SetURLSearchParams;
  phraseGroupId?: number;
}

const PhraseList = ({ state, setSearchParams, phraseGroupId }: PhraseListProps): JSX.Element => {
  const { classes } = useStyles();
  const currentSize = useRef(state.meta?.per_page || defaultPagination.size);

  const paginationFetch = (page: number, pageSize?: number) => {
    let newPage = page <= 1 ? 1 : page;
    if (pageSize !== currentSize.current) {
      newPage = defaultPagination.page;
    }

    if (pageSize) {
      currentSize.current = pageSize;
    }

    setSearchParams(
      `?${new URLSearchParams({ page: newPage.toString(), size: currentSize.current.toString() })}`,
    );
  };

  const handlePaginationChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    if (
      (model.page !== state.meta?.current_page && model.page > 0) ||
      model.pageSize !== state.meta?.per_page
    ) {
      paginationFetch(model.page, model.pageSize);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => {
        if (phraseGroupId !== undefined) {
          return (
            <Link to={'/phrase_group/' + phraseGroupId + '/phrase/' + params.row.id}>
              {params.value}
            </Link>
          );
        }

        return <Link to={'/phrase/' + params.row.id}>{params.value}</Link>;
      },
    },
  ];

  if (phraseGroupId === undefined) {
    columns.push({
      field: 'phrase_group_id',
      headerName: 'Phrase Group ID',
      flex: 1,
      renderCell: (params) => {
        return <Link to={'/phrase_group/' + params.value}>{params.value}</Link>;
      },
    });
  }

  return (
    <div className={classes.root}>
      <DataGrid
        paginationMode="server"
        paginationModel={{
          page: state.meta?.current_page || defaultPagination.page,
          pageSize: state.meta?.per_page || defaultPagination.size,
        }}
        rows={state.data}
        pagination
        columns={columns}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[10, 20, 50, 100]}
        rowCount={state.meta?.total || 100}
        disableColumnMenu
        columnHeaderHeight={44}
        rowHeight={100}
        autoHeight
        columnBuffer={columns.length}
        loading={state.paginationLoading}
      />
    </div>
  );
};

export default PhraseList;
