import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import { ErrorResponse, UserResponse } from '../../../utilities/apiUtilities';
import { actions } from './UserProfileSlice';

export function* fetchUserProfile(): SagaIterator {
  try {
    const res: UserResponse = yield call(api.getUserProfile);
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* clearUserProfile(): SagaIterator {
  try {
    yield call(api.logout);
    yield put(actions.clearSuccess());
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.clearFailure(res));
  }
}

export default function* OverviewSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchUserProfile),
    yield takeEvery(actions.clearFetch, clearUserProfile),
  ]);
}
