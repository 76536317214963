import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TranslateIcon from '@mui/icons-material/Translate';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StairsIcon from '@mui/icons-material/Stairs';
import SchoolIcon from '@mui/icons-material/School';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import NotesIcon from '@mui/icons-material/Notes';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const drawerWidth = 240;

interface SidebarProps {
  open: boolean;
  toggleDrawer: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function Sidebar({ open, toggleDrawer }: SidebarProps) {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => {
    setExpanded(expanded !== panel ? panel : false);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        {open && (
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Lingobricks Admin
          </Typography>
        )}
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItemButton onClick={() => navigation('/')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton onClick={() => navigation('/language')}>
          <ListItemIcon>
            <TranslateIcon />
          </ListItemIcon>
          <ListItemText primary="Language" />
        </ListItemButton>
        <ListItemButton onClick={() => navigation('/question')}>
          <ListItemIcon>
            <QuestionMarkIcon />
          </ListItemIcon>
          <ListItemText primary="Question" />
        </ListItemButton>
        <ListItemButton onClick={() => navigation('/lesson_group')}>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Lesson Group" />
        </ListItemButton>
        <ListItemButton onClick={() => navigation('/level')}>
          <ListItemIcon>
            <StairsIcon />
          </ListItemIcon>
          <ListItemText primary="Level" />
        </ListItemButton>
        <ListItemButton onClick={() => handleChange('situation')}>
          <ListItemIcon>
            <PlayLessonIcon />
          </ListItemIcon>
          <ListItemText primary="Situation" />
          {expanded === 'situation' ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded === 'situation'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton onClick={() => navigation('/situation')}>
              <ListItemText primary="List" />
            </ListItemButton>
            <ListItemButton onClick={() => navigation('/situation_group')}>
              <ListItemText primary="Group" />
            </ListItemButton>
          </List>
        </Collapse>
        {expanded === 'situation' && <Divider sx={{ my: 1 }} />}
        <ListItemButton onClick={() => handleChange('phrase')}>
          <ListItemIcon>
            <VoiceChatIcon />
          </ListItemIcon>
          <ListItemText primary="Phrase" />
          {expanded === 'phrase' ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded === 'phrase'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton onClick={() => navigation('/phrase')}>
              <ListItemText primary="List" />
            </ListItemButton>
            <ListItemButton onClick={() => navigation('/phrase_group')}>
              <ListItemText primary="Group" />
            </ListItemButton>
          </List>
        </Collapse>
        {expanded === 'phrase' && <Divider sx={{ my: 1 }} />}
        {expanded === 'sentence' && <Divider sx={{ my: 1 }} />}
        <ListItemButton onClick={() => handleChange('sentence')}>
          <ListItemIcon>
            <NotesIcon />
          </ListItemIcon>
          <ListItemText primary="Sentence" />
          {expanded === 'sentence' ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded === 'sentence'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton onClick={() => navigation('/sentence')}>
              <ListItemText primary="List" />
            </ListItemButton>
            <ListItemButton onClick={() => navigation('/sentence_type')}>
              <ListItemText primary="Type" />
            </ListItemButton>
            <ListItemButton onClick={() => navigation('/sentence_template')}>
              <ListItemText primary="Template" />
            </ListItemButton>
          </List>
        </Collapse>
        {expanded === 'sentence' && <Divider sx={{ my: 1 }} />}
      </List>
    </Drawer>
  );
}

export default Sidebar;
