import { CssBaseline, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import NonAuthRoutes from './navigation/NonAuthRoutes';
import AuthRoutes from './navigation/AuthRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated, selectIsLoading } from './modules/auth/modules/UserProfileSelector';
import { actions } from './modules/auth/modules/UserProfileSlice';
import { Fragment, useEffect } from 'react';
import LoadingSpinner from './components/LoadingSpinner';
import { getAccessToken } from './utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
  },
}));

function App() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const isLoggedIn = useSelector(selectIsAuthenticated);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      dispatch(actions.fetch());
    } else {
      dispatch(actions.setLoading(false));
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Fragment>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            {!isLoggedIn && <NonAuthRoutes />}
            {isLoggedIn && <AuthRoutes />}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
}

export default App;
