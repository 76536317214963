import { Button, DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SwitchElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import { LanguageResponse } from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions } from './modules/LanguageSlice';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { errorUpdateMessage, updatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface LanguageEditModalProps {
  language: LanguageResponse;
}

export interface LanguageEditModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  description: string;
  image?: string;
  published: number;
};

const LanguageEditModal = forwardRef(
  (props: LanguageEditModalProps, ref: Ref<LanguageEditModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const defaultValues: FormProps = {
      title: props.language.title,
      description: props.language.description || '',
      image: undefined,
      published: props.language.published,
    };
    const [imageFile, setImageFile] = useState<File | undefined>(undefined);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps, file: File | undefined) => {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('published', values.published.toString() == 'true' ? '1' : '0');

      if (file) {
        formData.append('image', file);
      }

      const response = await api
        .putLanguage<LanguageResponse>(props.language.id, formData)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(actions.fetchDetailSuccess(response));
        dispatch(alertActions.setAlert(updatedMessage('Language', response.title)));
      } else {
        dispatch(alertActions.setAlert(errorUpdateMessage('Language', values.title)));
      }

      handleClose();
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer
          defaultValues={defaultValues}
          onSuccess={(data) => onSuccess(data, imageFile)}
        >
          <ModalDialogTitle onClose={handleClose} id="edit-modal-title">
            EDIT LANGUAGE
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
              <TextFieldElement multiline minRows={3} name={'description'} label={'Description'} />
              <TextFieldElement
                name={'image'}
                label={'Image'}
                type="file"
                onChange={(e) => {
                  setImageFile((e.target as HTMLInputElement)?.files?.[0]);
                }}
              />
              <SwitchElement name="published" label="Published" />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default LanguageEditModal;
