import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { DataGrid, GridCallbackDetails, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';

import { SituationState } from './modules/SituationSlice';
import { Link, SetURLSearchParams } from 'react-router-dom';
import { defaultPagination } from '../../utilities/utils';
import ImageViewer, { ImageViewerRef } from '../../components/ImageViewer';
import { ImageResponse } from '../../utilities/apiUtilities';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
  },
}));

interface SituationListProps {
  state: SituationState;
  setSearchParams: SetURLSearchParams;
  situationGroupId?: number;
}

const SituationList = ({
  state,
  setSearchParams,
  situationGroupId,
}: SituationListProps): JSX.Element => {
  const { classes } = useStyles();
  const currentSize = useRef(state.meta?.per_page || defaultPagination.size);
  const [imageViewerImage, setImageViewerImage] = useState<ImageResponse | undefined>(undefined);
  const imageViewerRef = useRef<ImageViewerRef>(null);

  const handleImageClick = (image: ImageResponse) => {
    setImageViewerImage(image);
  };

  const handleImageClose = () => {
    setImageViewerImage(undefined);
  };

  const paginationFetch = (page: number, pageSize?: number) => {
    let newPage = page <= 1 ? 1 : page;
    if (pageSize !== currentSize.current) {
      newPage = defaultPagination.page;
    }

    if (pageSize) {
      currentSize.current = pageSize;
    }

    setSearchParams(
      `?${new URLSearchParams({ page: newPage.toString(), size: currentSize.current.toString() })}`,
    );
  };

  const handlePaginationChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    if (
      (model.page !== state.meta?.current_page && model.page > 0) ||
      model.pageSize !== state.meta?.per_page
    ) {
      paginationFetch(model.page, model.pageSize);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => {
        if (situationGroupId !== undefined) {
          return (
            <Link to={'/situation_group/' + situationGroupId + '/situation/' + params.row.id}>
              {params.value}
            </Link>
          );
        }

        return <Link to={'/situation/' + params.row.id}>{params.value}</Link>;
      },
    },
    {
      field: 'image',
      headerName: 'Image',
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return (
            <Button size="small" onClick={() => handleImageClick(params.value)}>
              {params.value.original_name}
            </Button>
          );
        }

        return <></>;
      },
    },
  ];

  if (situationGroupId === undefined) {
    columns.push({
      field: 'situation_group_id',
      headerName: 'Situation Group',
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={'/situation_group/' + params.value}>{params.row?.situation_group?.title}</Link>
        );
      },
    });
  }

  useEffect(() => {
    if (imageViewerImage && imageViewerRef.current) {
      imageViewerRef.current.open();
    }
  }, [imageViewerImage]);

  return (
    <div className={classes.root}>
      <DataGrid
        paginationMode="server"
        paginationModel={{
          page: state.meta?.current_page || defaultPagination.page,
          pageSize: state.meta?.per_page || defaultPagination.size,
        }}
        rows={state.data}
        pagination
        columns={columns}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[10, 20, 50, 100]}
        rowCount={state.meta?.total || 100}
        disableColumnMenu
        columnHeaderHeight={44}
        rowHeight={100}
        autoHeight
        columnBuffer={columns.length}
        loading={state.paginationLoading}
      />
      {imageViewerImage && (
        <ImageViewer ref={imageViewerRef} image={imageViewerImage} onClose={handleImageClose} />
      )}
    </div>
  );
};

export default SituationList;
