import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import {
  PaginationResponse,
  PhraseGroupResponse,
  PhraseResponse,
} from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions } from './modules/PhraseSlice';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { errorUpdateMessage, updatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface PhraseEditModalProps {
  phrase: PhraseResponse;
  phraseGroupId?: number;
}

export interface PhraseEditModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  phrase_group_id: number;
};

const PhraseEditModal = forwardRef((props: PhraseEditModalProps, ref: Ref<PhraseEditModalRef>) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [PhraseGroupOptions, setPhraseGroupOptions] = useState<{ id: string; label: string }[]>([]);

  const defaultValues: FormProps = {
    title: props.phrase.title,
    phrase_group_id: props.phrase.phrase_group_id,
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSuccess = async (values: FormProps) => {
    const response = await api
      .putPhrase<PhraseResponse>(props.phrase.id, values)
      .then((res) => res)
      .catch((err) => undefined);

    if (response) {
      dispatch(actions.fetchDetailSuccess(response));
      dispatch(alertActions.setAlert(updatedMessage('Phrase', response.title)));
    } else {
      dispatch(alertActions.setAlert(errorUpdateMessage('Phrase', values.title)));
    }

    handleClose();
  };

  const formatList = (list: PhraseGroupResponse[]) => {
    return list.map((item) => {
      return { id: item.id, label: item.title || 'Unknown' };
    });
  };

  useEffect(() => {
    (async () => {
      if (props.phraseGroupId !== undefined) {
        const phraseGroup = await api
          .getPhraseGroupById<PhraseGroupResponse>(props.phraseGroupId.toString())
          .then((res) => formatList([res]))
          .catch((err) => []);
        setPhraseGroupOptions(phraseGroup);
      } else {
        const size = 100;
        const page = 0;
        const PhraseGroups = await api
          .getPhraseGroupList<PaginationResponse<PhraseGroupResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        setPhraseGroupOptions(PhraseGroups);
      }
    })();
  }, []);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <ModalDialog open={open} onClose={handleClose}>
      <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
        <ModalDialogTitle onClose={handleClose} id="edit-modal-title">
          EDIT PHRASE
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <TextFieldElement name={'title'} label={'Title'} />
            <SelectElement
              name={'phrase_group_id'}
              label={'Phrase Group'}
              options={PhraseGroupOptions}
              fullWidth
              disabled={props.phraseGroupId !== undefined}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type={'submit'}>Save</Button>
        </DialogActions>
      </FormContainer>
    </ModalDialog>
  );
});

export default PhraseEditModal;
