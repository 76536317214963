import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { UserProfileState } from './UserProfileSlice';

const userProfile = (state: RootState): UserProfileState => state.userProfile;

export const selectUserProfile: Selector<RootState, UserProfileState> = createSelector(
  userProfile,
  (user_profile) => user_profile,
);

export const selectIsAuthenticated = createSelector(
  selectUserProfile,
  (user_profile) => user_profile?.profile !== undefined,
);

export const selectIsLoading = createSelector(
  selectUserProfile,
  (user_profile) => user_profile.loading,
);

export const selectUser = createSelector(
  selectUserProfile,
  (user_profile) => user_profile?.profile,
);
