import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  PaginationResponse,
  LanguageResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './LanguageSlice';

export function* fetchLanguageList(
  action: PayloadAction<{ size: number; page: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<LanguageResponse> = yield call(api.getLanguageList, size, page);
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchLanguageId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: LanguageResponse = yield call(api.getLanguageById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* LanguageSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchLanguageList),
    yield takeEvery(actions.fetchDetail, fetchLanguageId),
  ]);
}
