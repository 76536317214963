import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ErrorResponse,
  LessonTemplatePhraseGroupResponse,
  PaginationResponse,
  QuestionListResponse,
} from '../../../utilities/apiUtilities';

export type LessonTemplateState = {
  lesson_group_id: string;
  questionList: QuestionListResponse[];
  loading: boolean;
  error?: ErrorResponse;
  lessonTemplateList: LessonTemplatePhraseGroupResponse[];
  lessonTemplateListLoading: boolean;
  lessonTemplateListError?: ErrorResponse;
  questionRefresh: boolean;
};

export const initialState: LessonTemplateState = {
  lesson_group_id: '',
  questionList: [],
  loading: false,
  lessonTemplateList: [],
  lessonTemplateListLoading: false,
  lessonTemplateListError: undefined,
  questionRefresh: false,
};

export const LessonTemplateSlice = createSlice({
  name: 'lessonTemplate',
  initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ lesson_group_id: string }>) {
      state.lesson_group_id = action.payload.lesson_group_id;
      state.loading = true;
      state.error = undefined;
      state.questionList = [];
    },
    fetchSuccess(state, action: PayloadAction<QuestionListResponse[]>) {
      state.questionList = action.payload;
      state.loading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
    fetchLessonTemplate(state, action: PayloadAction<{ lesson_group_id: string }>) {
      state.lesson_group_id = action.payload.lesson_group_id;
      state.lessonTemplateListLoading = true;
      state.lessonTemplateListError = undefined;
      state.lessonTemplateList = [];
    },
    fetchLessonTemplateSuccess(
      state,
      action: PayloadAction<PaginationResponse<LessonTemplatePhraseGroupResponse>>,
    ) {
      state.lessonTemplateList = action.payload.data;
      state.lessonTemplateListLoading = false;
    },
    fetchLessonTemplateFailure(state, action: PayloadAction<ErrorResponse>) {
      state.lessonTemplateListError = action.payload;
      state.lessonTemplateListLoading = false;
    },
    addLessonTemplate(state, action: PayloadAction<LessonTemplatePhraseGroupResponse>) {
      state.lessonTemplateList.push(action.payload);
    },
    removeLessonTemplate(state, action: PayloadAction<{ id: string }>) {
      state.lessonTemplateList = state.lessonTemplateList.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    fetchOneQuestion(
      state,
      action: PayloadAction<{ question_id: string; lesson_group_id: string }>,
    ) {
      state.questionRefresh = true;
    },
    fetchOneQuestionSuccess(state, action: PayloadAction<QuestionListResponse>) {
      const question = action.payload;

      state.questionList = state.questionList.map((item) => {
        if (item.id === question.id) {
          return question;
        }
        return item;
      });

      state.questionRefresh = false;
    },
    fetchOneQuestionFailure(state, action: PayloadAction<ErrorResponse>) {
      state.questionRefresh = false;
    },
  },
});

export const { name, actions, reducer } = LessonTemplateSlice;
