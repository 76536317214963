import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import { ErrorResponse, LevelResponse, PaginationResponse } from '../../../utilities/apiUtilities';
import { actions } from './LevelSlice';

export function* fetchLevelList(
  action: PayloadAction<{ size: number; page: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<LevelResponse> = yield call(api.getLevelList, size, page);
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchLevelId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: LevelResponse = yield call(api.getLevelById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* LevelSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchLevelList),
    yield takeEvery(actions.fetchDetail, fetchLevelId),
  ]);
}
