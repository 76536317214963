import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SwitchElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { LanguageResponse } from '../../utilities/apiUtilities';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

export interface LanguageAddModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  description: string;
  image?: string;
  published: number;
};

const LanguageAddModal = forwardRef(({}, ref: Ref<LanguageAddModalRef>) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultValues: FormProps = {
    title: '',
    description: '',
    image: undefined,
    published: 0,
  };
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSuccess = async (values: FormProps, file: File | undefined) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('published', values.published.toString() == 'true' ? '1' : '0');

    if (file) {
      formData.append('image', file);
    }

    const response = await api
      .postLanguage<LanguageResponse>(formData)
      .then((res) => res)
      .catch((err) => undefined);

    if (response) {
      dispatch(alertActions.setAlert(createdMessage('Language', response.title)));
      navigate('/language/' + response.id);
    } else {
      dispatch(alertActions.setAlert(errorCreatedMessage('Language', values.title)));
      handleClose();
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <ModalDialog open={open} onClose={handleClose}>
      <FormContainer defaultValues={defaultValues} onSuccess={(data) => onSuccess(data, imageFile)}>
        <ModalDialogTitle onClose={handleClose} id="add-modal-title">
          ADD LANGUAGE
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <TextFieldElement name={'title'} label={'Title'} />
            <TextFieldElement multiline minRows={3} name={'description'} label={'Description'} />
            <TextFieldElement
              name={'image'}
              label={'Image'}
              type="file"
              onChange={(e) => {
                setImageFile((e.target as HTMLInputElement)?.files?.[0]);
              }}
            />
            <SwitchElement name="published" label="Published" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type={'submit'}>Save</Button>
        </DialogActions>
      </FormContainer>
    </ModalDialog>
  );
});

export default LanguageAddModal;
