import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LoadingSection from '../../components/LoadingSection';
import Breadcrumbs from '../../components/Breadcrumbs';
import { actions } from './modules/LessonSlice';
import { selectLessonData } from './modules/LessonSelector';
import LessonEditModal, { LessonEditModalRef } from './LessonEditModal';
import LessonDeleteModal, { LessonDeleteModalRef } from './LessonDeleteModal';
import LessonDetailQuestion from './components/LessonDetailQuestions';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

const actionOptions = ['Edit', 'Delete'];

function LessonDetailPage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(true);
  const lesson = useSelector(selectLessonData, shallowEqual);
  const params = useParams();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<LessonEditModalRef>(null);
  const deleteRef = useRef<LessonDeleteModalRef>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (index === 0) {
      editRef.current?.open();
    }

    if (index === 1) {
      deleteRef.current?.open();
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const fetchData = (lessonId: string) => {
    return dispatch(actions.fetchDetail({ id: lessonId }));
  };

  useEffect(() => {
    if (!lesson?.loading) {
      fetchData(params.id || '');
    }
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowLessonDetail = () => {
    if (showLoading || lesson.loading || lesson.error) {
      return <LoadingSection error={lesson.error} />;
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Title
              </TableCell>
              <TableCell align="right">{lesson.lessonDetail?.title}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Lesson Group ID
              </TableCell>
              <TableCell align="right">
                <Link to={`/lesson_group/${lesson.lessonDetail?.lesson_group_id}`}>
                  {lesson.lessonDetail?.lesson_group_id}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Published/Draft
              </TableCell>
              <TableCell align="right">
                {lesson.lessonDetail?.published === 1 ? 'Published' : 'Draft'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/lesson_group', name: 'Lesson Group' },
                  {
                    path: '/lesson_group/' + params.lesson_group_id,
                    name: params.lesson_group_id || 'Unknown',
                  },
                  { path: '/lesson_group/' + params.lesson_group_id + '/lesson', name: 'Lesson' },
                  {
                    path: '/lesson_group/' + params.lesson_group_id + '/lesson/' + params.id,
                    name: params.id || 'Unknown',
                  },
                ]}
              />
            </Box>
            <Box>
              <ButtonGroup ref={anchorRef}>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={handleToggle}
                >
                  Actions
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="button-menu" autoFocusItem>
                          {actionOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {/* <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {id || 'Unknown'}
              </Typography> */}
            <ShowLessonDetail />
            {lesson?.lessonDetail && <LessonDetailQuestion lesson={lesson?.lessonDetail} />}
          </Paper>
        </Grid>
      </Grid>
      {lesson?.lessonDetail && (
        <Fragment>
          <LessonEditModal ref={editRef} lesson={lesson.lessonDetail} />
          <LessonDeleteModal ref={deleteRef} lesson={lesson.lessonDetail} />
        </Fragment>
      )}
    </div>
  );
}

export default LessonDetailPage;
