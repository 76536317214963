import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../../components/ModalDialog';
import {
  LessonTemplatePhraseGroupResponse,
  PaginationResponse,
  PhraseGroupResponse,
  SentenceTemplateResponse,
} from '../../../utilities/apiUtilities';
import { actions } from '../modules/LessonTemplateSlice';
import { actions as alertActions } from '../../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

export interface LessonTemplatePhraseGroupAddModalRef {
  open: () => void;
  close: () => void;
}

interface LessonTemplatePhraseGroupAddModalProps {
  lesson_group_id: string;
  handleClose?: () => void;
}

type FormProps = {
  name: string;
  phrase_group_id: number;
};

const LessonTemplatePhraseGroupAddModal = forwardRef(
  (
    props: LessonTemplatePhraseGroupAddModalProps,
    ref: Ref<LessonTemplatePhraseGroupAddModalRef>,
  ) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [phraseGroupOptions, setPhraseGroupOptions] = useState<{ id: string; label: string }[]>(
      [],
    );

    const defaultValues: FormProps = {
      name: '',
      phrase_group_id: 0,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      if (props.handleClose) {
        props.handleClose();
      }
    };

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .postLessonTemplatePhraseGroup<LessonTemplatePhraseGroupResponse>(
          props.lesson_group_id,
          values,
        )
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(
          alertActions.setAlert(createdMessage('Lesson Template Phrase Group', response.name)),
        );
        dispatch(actions.addLessonTemplate(response));
        handleClose();
      } else {
        dispatch(
          alertActions.setAlert(errorCreatedMessage('Lesson Template Phrase Group', values.name)),
        );
        handleClose();
      }
    };

    const formatList = (list: Array<SentenceTemplateResponse | PhraseGroupResponse>) => {
      return list.map((item) => {
        return { id: item.id, label: item.title || 'Unknown' };
      });
    };

    useEffect(() => {
      (async () => {
        const size = 100;
        const page = 0;
        const phraseGroups = await api
          .getPhraseGroupList<PaginationResponse<PhraseGroupResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        setPhraseGroupOptions(phraseGroups);
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="add-modal-title">
            ADD LESSON TEMPLATE PHRASE GROUP
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'name'} label={'Name'} />
              <SelectElement
                name={'phrase_group_id'}
                label={'Phrase Group'}
                options={phraseGroupOptions}
                fullWidth
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default LessonTemplatePhraseGroupAddModal;
