import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { LessonState } from './LessonSlice';

const LessonData = (state: RootState): LessonState => state.lesson;

export const selectLessonData: Selector<RootState, LessonState> = createSelector(
  LessonData,
  (lesson) => lesson,
);

export const selectLessonDetail = createSelector(LessonData, (lesson) => lesson?.lessonDetail);

export const selectLessonList = createSelector(LessonData, (lesson) => lesson?.data);

export const selectLessonTranslated = createSelector(LessonData, (lesson) => lesson?.translate);

export const selectLanguageList = createSelector(LessonData, (lesson) => lesson?.languageList);
