import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { SentenceTypeState } from './SentenceTypeSlice';

const SentenceTypeData = (state: RootState): SentenceTypeState => state.sentenceType;

export const selectSentenceTypeData: Selector<RootState, SentenceTypeState> = createSelector(
  SentenceTypeData,
  (sentenceType) => sentenceType,
);

export const selectSentenceTypeDetail = createSelector(
  SentenceTypeData,
  (sentenceType) => sentenceType?.sentenceTypeDetail,
);
