import { Button, DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';

import api from '../../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../../components/ModalDialog';
import { LessonTemplatePhraseGroupResponse } from '../../../utilities/apiUtilities';
import { actions } from '../modules/LessonTemplateSlice';
import { actions as alertActions } from '../../alert/modules/AlertSlice';
import { deletedMessage } from '../../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface LessonTemplatePhraseGroupDeleteModalProps {
  lesson_group_id: string;
  lesson_template_phrase_group: LessonTemplatePhraseGroupResponse;
  closeCallback?: () => void;
}

export interface LessonTemplatePhraseGroupDeleteModalRef {
  open: () => void;
  close: () => void;
}

const LessonTemplatePhraseGroupDeleteModal = forwardRef(
  (
    props: LessonTemplatePhraseGroupDeleteModalProps,
    ref: Ref<LessonTemplatePhraseGroupDeleteModalRef>,
  ) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      if (props.closeCallback) {
        props.closeCallback();
      }
    };

    const onClick = async () => {
      await api.deleteLessonTemplatePhraseGroup(
        props.lesson_group_id,
        props.lesson_template_phrase_group.id || '',
      );
      handleClose();
      dispatch(
        alertActions.setAlert(
          deletedMessage('Lesson Template Phrase Group', props.lesson_template_phrase_group.name),
        ),
      );
      dispatch(actions.removeLessonTemplate({ id: props.lesson_template_phrase_group.id || '' }));
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <ModalDialogTitle onClose={handleClose} id="delete-modal-title">
          DELETE LESSON TEMPLATE PHRASE GROUP
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <Typography fontStyle={'italic'} gutterBottom>
              Are you sure you want to delete this lesson template phrase group?
            </Typography>
            <Typography gutterBottom>Name: {props.lesson_template_phrase_group.name}</Typography>
            <Typography gutterBottom>
              Phrase Group: {props.lesson_template_phrase_group.phrase_group.title}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onClick}>Delete</Button>
        </DialogActions>
      </ModalDialog>
    );
  },
);

export default LessonTemplatePhraseGroupDeleteModal;
