import { Button, DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../../../services/api.service';
import { SentenceTemplateResponse } from '../../../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../../../components/ModalDialog';
import { actions as alertActions } from '../../../alert/modules/AlertSlice';
import { deletedMessage } from '../../../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface SentenceTemplateDeleteModalProps {
  sentenceTemplate: SentenceTemplateResponse;
  onCloseCallback?: (sentenceTemplate?: SentenceTemplateResponse) => void;
}

export interface SentenceTemplateDeleteModalRef {
  open: () => void;
  close: () => void;
}

const SentenceTemplateDeleteModal = forwardRef(
  (props: SentenceTemplateDeleteModalProps, ref: Ref<SentenceTemplateDeleteModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = (sentence_template?: SentenceTemplateResponse) => {
      setOpen(false);
      if (props.onCloseCallback) {
        props.onCloseCallback(sentence_template);
      }
    };

    const onClick = async () => {
      await api.deleteSentenceTemplate(props.sentenceTemplate.id);
      handleClose(props.sentenceTemplate);
      dispatch(
        alertActions.setAlert(deletedMessage('Sentence Template', props.sentenceTemplate.title)),
      );
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={() => handleClose()}>
        <ModalDialogTitle onClose={() => handleClose()} id="delete-modal-title">
          DELETE SENTENCE TEMPLATE
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <Typography fontStyle={'italic'} gutterBottom>
              Are you sure you want to delete this sentence template?
            </Typography>
            <Typography gutterBottom>Title: {props.sentenceTemplate.title}</Typography>
            <Typography gutterBottom>Number: {props.sentenceTemplate.number}</Typography>
            <Typography gutterBottom>Question ID: {props.sentenceTemplate.question_id}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={onClick}>Delete</Button>
        </DialogActions>
      </ModalDialog>
    );
  },
);

export default SentenceTemplateDeleteModal;
