import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../modules/auth/Login';
import FindAccount from '../modules/auth/FindAccount';
import ResetPassword from '../modules/auth/ResetPassword';

function NonAuthRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/find-account" element={<FindAccount />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );
}

export default NonAuthRoutes;
