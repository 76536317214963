export const defaultPagination = {
  size: 10,
  page: 0,
};

export const storeAccessToken = (token: string) => {
  localStorage.setItem('accessToken', token);
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const removeAccessToken = () => {
  localStorage.removeItem('accessToken');
};

export const authHeader = () => {
  const token = getAccessToken() as string;
  if (token) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
};
