import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { SentenceState } from './SentenceSlice';

const SentenceData = (state: RootState): SentenceState => state.sentence;

export const selectSentenceData: Selector<RootState, SentenceState> = createSelector(
  SentenceData,
  (sentence) => sentence,
);

export const selectSentenceDetail = createSelector(
  SentenceData,
  (sentence) => sentence?.sentenceDetail,
);
