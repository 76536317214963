import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { SituationGroupState } from './SituationGroupSlice';

const SituationGroupData = (state: RootState): SituationGroupState => state.situationGroup;

export const selectSituationGroupData: Selector<RootState, SituationGroupState> = createSelector(
  SituationGroupData,
  (situationGroup) => situationGroup,
);

export const selectSituationGroupDetail = createSelector(
  SituationGroupData,
  (situationGroup) => situationGroup?.situationGroupDetail,
);
