import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SwitchElement, SelectElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import {
  LessonGroupResponse,
  LessonDetailResponse,
  PaginationResponse,
} from '../../utilities/apiUtilities';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

export interface LessonAddModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  lesson_group_id: number;
  published: number;
};

interface LessonAddModalProps {
  lesson_group_id: number;
}

const LessonAddModal = forwardRef((props: LessonAddModalProps, ref: Ref<LessonAddModalRef>) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lessonGroupOptions, setLessonGroupOptions] = useState<{ id: string; label: string }[]>([]);

  const defaultValues: FormProps = {
    title: '',
    lesson_group_id: props.lesson_group_id,
    published: 0,
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSuccess = async (values: FormProps) => {
    const response = await api
      .postLessonGroupCreateLesson<LessonDetailResponse>(props.lesson_group_id.toString(), values)
      .then((res) => res)
      .catch((err) => undefined);

    if (response) {
      dispatch(alertActions.setAlert(createdMessage('Lesson', response.title)));
      navigate('/lesson_group/' + props.lesson_group_id + '/lesson/' + response.lesson_id);
    } else {
      dispatch(alertActions.setAlert(errorCreatedMessage('Lesson', values.title)));
      handleClose();
    }
  };

  const formatList = (list: LessonGroupResponse[]) => {
    return list.map((item) => {
      return {
        id: item.id,
        label: item.title || 'LessonGroup #' + item.id + ' (No Description)',
      };
    });
  };

  useEffect(() => {
    (async () => {
      const size = 100;
      const page = 0;
      const lessonGroups = await api
        .getLessonGroupList<PaginationResponse<LessonGroupResponse>>(size, page)
        .then((res) => formatList(res.data))
        .catch((err) => []);
      setLessonGroupOptions(lessonGroups);
    })();
  }, []);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <ModalDialog open={open} onClose={handleClose}>
      <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
        <ModalDialogTitle onClose={handleClose} id="add-modal-title">
          ADD LESSON
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <TextFieldElement name={'title'} label={'Title'} />
            <SelectElement
              name={'lesson_group_id'}
              label={'Lesson Group'}
              options={lessonGroupOptions}
              disabled
              fullWidth
            />
            <SwitchElement name="published" label="Published" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type={'submit'}>Save</Button>
        </DialogActions>
      </FormContainer>
    </ModalDialog>
  );
});

export default LessonAddModal;
