import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import {
  SentenceTemplateResponse,
  SentenceResponse,
  QuestionListResponse,
  LessonResponse,
  LanguageResponse,
  PaginationResponse,
} from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions } from './modules/SentenceSlice';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { errorUpdateMessage, updatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface SentenceEditModalProps {
  sentence: SentenceResponse;
}

export interface SentenceEditModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  sentence_template_id: string;
  language_id: string;
  lesson_id: string;
  question_id: number;
};

const SentenceEditModal = forwardRef(
  (props: SentenceEditModalProps, ref: Ref<SentenceEditModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [sentenceTemplateOptions, setSentenceTemplateOptions] = useState<
      { id: string; label: string }[]
    >([]);
    const [languageOptions, setLanguageOptions] = useState<{ id: string; label: string }[]>([]);
    const [lessonOptions, setLessonOptions] = useState<{ id: string; label: string }[]>([]);
    const [questionOptions, setQuestionOptions] = useState<{ id: string; label: string }[]>([]);

    const defaultValues: FormProps = {
      title: props.sentence.title,
      sentence_template_id: props.sentence.sentence_template_id,
      language_id: props.sentence.language_id,
      lesson_id: props.sentence.lesson_id,
      question_id: props.sentence.question_id,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .putSentence<SentenceResponse>(props.sentence.id, values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(actions.fetchDetailSuccess(response));
        dispatch(alertActions.setAlert(updatedMessage('Sentence', response.title)));
      } else {
        dispatch(alertActions.setAlert(errorUpdateMessage('Sentence', values.title)));
      }

      handleClose();
    };

    const formatList = (
      list: Array<
        SentenceTemplateResponse | LanguageResponse | LessonResponse | QuestionListResponse
      >,
    ) => {
      return list.map((item) => {
        let label: string;
        if ('title' in item) {
          label = item.title;
        } else if ('description' in item) {
          label = (item.description as string) || '';
        } else {
          label = 'Unknown #' + item.id;
        }

        return { id: item.id, label: label };
      });
    };

    useEffect(() => {
      (async () => {
        const size = 100;
        const page = 0;
        const sentenceTemplates = await api
          .getSentenceTemplateList<PaginationResponse<SentenceTemplateResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        const languages = await api
          .getLanguageList<PaginationResponse<LanguageResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        const lessons = await api
          .getLessonList<PaginationResponse<LessonResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        const questions = await api
          .getQuestionList<PaginationResponse<QuestionListResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);

        setSentenceTemplateOptions(sentenceTemplates);
        setLanguageOptions(languages);
        setLessonOptions(lessons);
        setQuestionOptions(questions);
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="edit-modal-title">
            EDIT SENTENCE
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
              <SelectElement
                name={'sentence_template_id'}
                label={'Sentence Template'}
                options={sentenceTemplateOptions}
                fullWidth
              />
              <SelectElement
                name={'language_id'}
                label={'Language'}
                options={languageOptions}
                fullWidth
              />
              <SelectElement
                name={'lesson_id'}
                label={'Lesson'}
                options={lessonOptions}
                fullWidth
              />
              <SelectElement
                name={'question_id'}
                label={'Question'}
                options={questionOptions}
                fullWidth
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default SentenceEditModal;
