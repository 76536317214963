import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { AlertState } from './AlertSlice';

const alertData = (state: RootState): AlertState => state.alert;

export const selectAlertData: Selector<RootState, AlertState> = createSelector(
  alertData,
  (alert) => alert,
);
