import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions } from '../modules/AlertSlice';
import { selectAlertData } from '../modules/AlertSelector';

const AlertMessage = (): JSX.Element => {
  const dispatch = useDispatch();
  const alert = useSelector(selectAlertData, shallowEqual);

  const handleClose = () => {
    dispatch(actions.clearAlert());
  };

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={alert.severity}>
        <AlertTitle>{alert.title}</AlertTitle>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
