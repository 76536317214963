import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import { ErrorResponse, OverviewResponse } from '../../../utilities/apiUtilities';
import { actions } from './OverviewSlice';

export function* fetchOverview(): SagaIterator {
  try {
    const res: OverviewResponse = yield call(api.getOverview);
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export default function* OverviewSaga(): SagaIterator {
  yield all([yield takeEvery(actions.fetch, fetchOverview)]);
}
