import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorResponse,
  LanguageResponse,
  PaginationMetaResponse,
  PaginationResponse,
} from '../../../utilities/apiUtilities';

export type LanguageState = {
  data: LanguageResponse[];
  loading: boolean;
  paginationLoading: boolean;
  error?: ErrorResponse;
  meta?: PaginationMetaResponse;
  languageDetail?: LanguageResponse;
};

export const initialState: LanguageState = {
  data: [],
  loading: false,
  paginationLoading: false,
};

export const LanguageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ size: number; page: number }>) {
      if (action.payload.page > 1) {
        state.paginationLoading = true;
      } else {
        state.loading = true;
      }
      state.error = undefined;
      state.data = [];
    },
    fetchSuccess(state, action: PayloadAction<PaginationResponse<LanguageResponse>>) {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.data = [];
      state.meta = undefined;
      state.error = action.payload;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchDetail(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
      state.error = undefined;
      state.languageDetail = undefined;
    },
    fetchDetailSuccess(state, action: PayloadAction<LanguageResponse>) {
      state.languageDetail = action.payload;
      state.loading = false;
    },
    fetchDetailFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { name, actions, reducer } = LanguageSlice;
