import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { PhraseState } from './PhraseSlice';

const PhraseData = (state: RootState): PhraseState => state.phrase;

export const selectPhraseData: Selector<RootState, PhraseState> = createSelector(
  PhraseData,
  (phrase) => phrase,
);

export const selectPhraseDetail = createSelector(PhraseData, (phrase) => phrase?.phraseDetail);
