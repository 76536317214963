import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LoadingSection from '../../components/LoadingSection';
import Breadcrumbs from '../../components/Breadcrumbs';
import { actions } from './modules/SentenceSlice';
import { selectSentenceData } from './modules/SentenceSelector';
import SentenceEditModal, { SentenceEditModalRef } from './SentenceEditModal';
import SentenceDeleteModal, { SentenceDeleteModalRef } from './SentenceDeleteModal';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

const actionOptions = ['Edit', 'Delete'];

function SentenceDetailPage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(true);
  const sentence = useSelector(selectSentenceData, shallowEqual);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<SentenceEditModalRef>(null);
  const deleteRef = useRef<SentenceDeleteModalRef>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (index === 0) {
      editRef.current?.open();
    }

    if (index === 1) {
      deleteRef.current?.open();
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const fetchData = (SentenceId: string) => {
    return dispatch(actions.fetchDetail({ id: SentenceId }));
  };

  useEffect(() => {
    if (!sentence?.loading) {
      fetchData(id || '');
    }
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowSentenceDetail = () => {
    if (showLoading || sentence.loading || sentence.error) {
      return <LoadingSection error={sentence.error} />;
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Title
              </TableCell>
              <TableCell align="right">{sentence.sentenceDetail?.title}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Sentence Template ID
              </TableCell>
              <TableCell align="right">
                <Link to={`/sentence_template/${sentence.sentenceDetail?.sentence_template_id}`}>
                  {sentence.sentenceDetail?.sentence_template_id}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Language ID
              </TableCell>
              <TableCell align="right">
                <Link to={`/language/${sentence.sentenceDetail?.language_id}`}>
                  {sentence.sentenceDetail?.language_id}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Lesson ID
              </TableCell>
              <TableCell align="right">{sentence.sentenceDetail?.lesson_id}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Question ID
              </TableCell>
              <TableCell align="right">{sentence.sentenceDetail?.question_id}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/sentence', name: 'Sentence' },
                  { path: '/sentence/' + id, name: id || 'Unknown' },
                ]}
              />
            </Box>
            <Box>
              <ButtonGroup ref={anchorRef}>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={handleToggle}
                >
                  Actions
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="button-menu" autoFocusItem>
                          {actionOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {/* <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {id || 'Unknown'}
              </Typography> */}
            <ShowSentenceDetail />
          </Paper>
        </Grid>
      </Grid>
      {sentence?.sentenceDetail && (
        <Fragment>
          <SentenceEditModal ref={editRef} sentence={sentence.sentenceDetail} />
          <SentenceDeleteModal ref={deleteRef} sentence={sentence.sentenceDetail} />
        </Fragment>
      )}
    </div>
  );
}

export default SentenceDetailPage;
