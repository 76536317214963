import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CircularProgress, DialogContent, ImageListItem, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ImageResponse } from '../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from './ModalDialog';
import api from '../services/api.service';

const useStyles = makeStyles()((theme: Theme) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '400px',
    width: '100%',
    alignItems: 'center',
  },
}));

interface ImageViewerProps {
  image: ImageResponse;
  onClose?: () => void;
}

export interface ImageViewerRef {
  open: () => void;
  close: () => void;
}

const ImageViewer = forwardRef((props: ImageViewerProps, ref: Ref<ImageViewerRef>) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  useEffect(() => {
    async function loadImage() {
      if (props.image) {
        setLoading(true);

        const response = await api.getImage(props.image.id);

        if (response) {
          setImageSrc(URL.createObjectURL(response));
        }

        setLoading(false);
      }
    }

    loadImage();
  }, [props.image]);

  return (
    <ModalDialog open={open} onClose={handleClose}>
      <ModalDialogTitle onClose={handleClose} id={`image-view-${props.image.id || ''}`}>
        {props.image.original_name}
      </ModalDialogTitle>
      <DialogContent dividers>
        <div className={classes.loading}>
          {loading ? (
            <CircularProgress />
          ) : (
            <img
              src={imageSrc}
              style={{ maxWidth: '100%' }}
              alt={props.image.original_name}
              loading="lazy"
            />
          )}
        </div>
      </DialogContent>
    </ModalDialog>
  );
});

export default ImageViewer;
