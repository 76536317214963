import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverviewResponse, ErrorResponse } from '../../../utilities/apiUtilities';

export type OverviewState = {
  data?: OverviewResponse;
  loading: boolean;
  error?: ErrorResponse;
};

export const initialState: OverviewState = {
  loading: false,
};

export const OverviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    fetch(state) {
      state.loading = true;
      state.error = undefined;
      state.data = undefined;
    },
    fetchSuccess(state, action: PayloadAction<OverviewResponse>) {
      state.data = action.payload;
      state.loading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { name, actions, reducer } = OverviewSlice;
