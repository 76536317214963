import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import { SentenceTemplateState, actions } from './modules/SentenceTemplateSlice';
import LoadingSection from '../../components/LoadingSection';
import { selectSentenceTemplateData } from './modules/SentenceTemplateSelector';
import SentenceTemplateList from './SentenceTemplateList';
import SentenceTemplateAddModal, { SentenceTemplateAddModalRef } from './SentenceTemplateAddModal';
import { defaultPagination } from '../../utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

function SentenceTemplatePage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const sentenceTemplates: SentenceTemplateState = useSelector(
    selectSentenceTemplateData,
    shallowEqual,
  );
  const [showLoading, setShowLoading] = useState(true);
  const addRef = useRef<SentenceTemplateAddModalRef>(null);

  const fetchData = (
    size = sentenceTemplates.meta?.per_page || defaultPagination.size,
    page = sentenceTemplates?.meta?.current_page || defaultPagination.page,
  ) => {
    return dispatch(actions.fetch({ size, page }));
  };

  useEffect(() => {
    if (!sentenceTemplates?.loading) {
      const size = Number(searchParams.get('size')) || defaultPagination.size;
      const page = Number(searchParams.get('page')) || defaultPagination.page;
      fetchData(size, page);
    }
  }, [location, searchParams]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowSentenceTemplateList = () => {
    if (showLoading || sentenceTemplates.loading || sentenceTemplates.error) {
      return <LoadingSection error={sentenceTemplates.error} />;
    }
    return <SentenceTemplateList state={sentenceTemplates} setSearchParams={setSearchParams} />;
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/sentence_template', name: 'Sentence Template' },
                ]}
              />
            </Box>
            <Box data-testid="alerts-refresh-button">
              <Button
                onClick={() => {
                  if (addRef.current) addRef.current.open();
                }}
                startIcon={<Add />}
                color="primary"
                variant="outlined"
              >
                Add Sentence Template
              </Button>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {!(showLoading || sentenceTemplates.loading || sentenceTemplates.error) && (
              <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {sentenceTemplates?.meta?.total || 0} Sentence Template
                {(sentenceTemplates?.meta?.total || 0) > 1 ? 's' : ''}
              </Typography>
            )}
            <ShowSentenceTemplateList />
          </Paper>
        </Grid>
      </Grid>
      <SentenceTemplateAddModal ref={addRef} />
    </div>
  );
}

export default SentenceTemplatePage;
