import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import { SituationState, actions } from './modules/SituationSlice';
import LoadingSection from '../../components/LoadingSection';
import { selectSituationData } from './modules/SituationSelector';
import SituationList from './SituationList';
import SituationAddModal, { SituationAddModalRef } from './SituationAddModal';
import { defaultPagination } from '../../utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  rootAlt: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(4, 0),
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

interface SituationPageProps {
  situationGroupId?: number;
}

function SituationPage({ situationGroupId }: SituationPageProps = {}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const situations: SituationState = useSelector(selectSituationData, shallowEqual);
  const [showLoading, setShowLoading] = useState(true);
  const addRef = useRef<SituationAddModalRef>(null);

  const fetchData = (
    size = situations.meta?.per_page || defaultPagination.size,
    page = situations?.meta?.current_page || defaultPagination.page,
    situation_group_id = situationGroupId,
  ) => {
    return dispatch(actions.fetch({ size, page, situation_group_id }));
  };

  useEffect(() => {
    if (!situations?.loading) {
      const size = Number(searchParams.get('size')) || defaultPagination.size;
      const page = Number(searchParams.get('page')) || defaultPagination.page;
      fetchData(size, page, situationGroupId);
    }
  }, [situationGroupId, location, searchParams]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowSituationList = () => {
    if (showLoading || situations.loading || situations.error) {
      return <LoadingSection error={situations.error} />;
    }
    return (
      <SituationList
        state={situations}
        setSearchParams={setSearchParams}
        situationGroupId={situationGroupId}
      />
    );
  };

  return (
    <div className={situationGroupId ? classes.rootAlt : classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          {!situationGroupId && (
            <Box display="flex">
              <Box flexGrow={1} alignSelf="center">
                <Breadcrumbs
                  id="alerts-breadcrumbs"
                  links={[
                    { path: '/', name: 'Dashboard' },
                    { path: '/situation', name: 'Situation' },
                  ]}
                />
              </Box>
              <Box data-testid="alerts-refresh-button">
                <Button
                  onClick={() => {
                    if (addRef.current) addRef.current.open();
                  }}
                  startIcon={<Add />}
                  color="primary"
                  variant="outlined"
                >
                  Add Situation
                </Button>
              </Box>
            </Box>
          )}
          <Paper className={classes.paper} elevation={0}>
            <Box display="flex">
              <Box flexGrow={1} alignSelf="center">
                {!(showLoading || situations.loading || situations.error) && (
                  <Typography variant="h5" color="textPrimary" marginBottom={1}>
                    {situations?.meta?.total || 0} Situation
                    {(situations?.meta?.total || 0) > 1 ? 's' : ''}
                  </Typography>
                )}
              </Box>
              <Box data-testid="alerts-add-button">
                {situationGroupId && (
                  <Button
                    onClick={() => {
                      if (addRef.current) addRef.current.open();
                    }}
                    startIcon={<Add />}
                    color="primary"
                    variant="outlined"
                  >
                    Add Situation
                  </Button>
                )}
              </Box>
            </Box>
            <ShowSituationList />
          </Paper>
        </Grid>
      </Grid>
      <SituationAddModal ref={addRef} situationGroupId={situationGroupId} />
    </div>
  );
}

export default SituationPage;
