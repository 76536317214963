import { AlertColor } from '@mui/material';

export const createdMessage = (title: string, name: string) => {
  return {
    title: `${title} Created`,
    message: `${name} has been created successfully.`,
    severity: 'success' as AlertColor,
    open: true,
  };
};

export const updatedMessage = (title: string, name: string) => {
  return {
    title: `${title} Updated`,
    message: `${name} has been updated successfully.`,
    severity: 'success' as AlertColor,
    open: true,
  };
};

export const deletedMessage = (title: string, name: string) => {
  return {
    title: `${title} Deleted`,
    message: `${name} has been deleted successfully.`,
    severity: 'success' as AlertColor,
    open: true,
  };
};

export const errorCreatedMessage = (title: string, name: string) => {
  return {
    title: `${title} Error`,
    message: `${name} has not been created successfully.`,
    severity: 'error' as AlertColor,
    open: true,
  };
};

export const errorDeleteMessage = (title: string, name: string) => {
  return {
    title: `${title} Error`,
    message: `${name} has not been deleted successfully.`,
    severity: 'error' as AlertColor,
    open: true,
  };
};

export const errorUpdateMessage = (title: string, name: string) => {
  return {
    title: `${title} Error`,
    message: `${name} has not been updated successfully.`,
    severity: 'error' as AlertColor,
    open: true,
  };
};
