/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { reducer as userProfileSlice } from './modules/auth/modules/UserProfileSlice';
import { reducer as languageSlice } from './modules/language/modules/LanguageSlice';
import { reducer as overviewSlice } from './modules/dashboard/modules/OverviewSlice';
import { reducer as lessonSlice } from './modules/lesson/modules/LessonSlice';
import { reducer as lessonGroupSlice } from './modules/lesson_group/modules/LessonGroupSlice';
import { reducer as levelSlice } from './modules/level/modules/LevelSlice';
import { reducer as situationSlice } from './modules/situation/modules/SituationSlice';
import { reducer as situationGroupSlice } from './modules/situation_group/modules/SituationGroupSlice';
import { reducer as LessonTemplateSlice } from './modules/lesson_template/modules/LessonTemplateSlice';
import { reducer as PhraseSlice } from './modules/phrase/modules/PhraseSlice';
import { reducer as PhraseGroupSlice } from './modules/phrase_group/modules/PhraseGroupSlice';
import { reducer as SentenceSlice } from './modules/sentence/modules/SentenceSlice';
import { reducer as SentenceTypeSlice } from './modules/sentence_type/modules/SentenceTypeSlice';
import { reducer as SentenceTemplateSlice } from './modules/sentence_template/modules/SentenceTemplateSlice';
import { reducer as QuestionSlice } from './modules/question/modules/QuestionSlice';
import { reducer as AlertSlice } from './modules/alert/modules/AlertSlice';

/* istanbul ignore file */
const combinedReducers = combineReducers({
  userProfile: userProfileSlice,
  language: languageSlice,
  overview: overviewSlice,
  lesson: lessonSlice,
  lessonGroup: lessonGroupSlice,
  level: levelSlice,
  situation: situationSlice,
  situationGroup: situationGroupSlice,
  lessonTemplate: LessonTemplateSlice,
  phrase: PhraseSlice,
  phraseGroup: PhraseGroupSlice,
  sentence: SentenceSlice,
  sentenceType: SentenceTypeSlice,
  sentenceTemplate: SentenceTemplateSlice,
  question: QuestionSlice,
  alert: AlertSlice,
});

export type RootState = ReturnType<typeof combinedReducers>;

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: [],
};

const rootReducer: any = (state: RootState, action: AnyAction) => {
  return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
