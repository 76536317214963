import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { OverviewState } from './OverviewSlice';

const OverviewData = (state: RootState): OverviewState => state.overview;

export const selectOverviewData: Selector<RootState, OverviewState> = createSelector(
  OverviewData,
  (overview) => overview,
);
