import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { QuestionState } from './QuestionSlice';

const QuestionData = (state: RootState): QuestionState => state.question;

export const selectQuestionData: Selector<RootState, QuestionState> = createSelector(
  QuestionData,
  (question) => question,
);

export const selectQuestionDetail = createSelector(
  QuestionData,
  (question) => question?.questionDetail,
);
