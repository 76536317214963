import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { LessonGroupState } from './LessonGroupSlice';

const LessonGroupData = (state: RootState): LessonGroupState => state.lessonGroup;

export const selectLessonGroupData: Selector<RootState, LessonGroupState> = createSelector(
  LessonGroupData,
  (lessonGroup) => lessonGroup,
);

export const selectLessonGroupDetail = createSelector(
  LessonGroupData,
  (lessonGroup) => lessonGroup?.lessonGroupDetail,
);
