import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import { ErrorResponse, PaginationResponse, PhraseResponse } from '../../../utilities/apiUtilities';
import { actions } from './PhraseSlice';

export function* fetchPhraseList(
  action: PayloadAction<{ size: number; page: number; phrase_group_id?: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  try {
    const res: PaginationResponse<PhraseResponse> = yield call(
      api.getPhraseList,
      size,
      page,
      action.payload?.phrase_group_id,
    );
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchPhraseId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const res: PhraseResponse = yield call(api.getPhraseById, id);
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export default function* PhraseSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchPhraseList),
    yield takeEvery(actions.fetchDetail, fetchPhraseId),
  ]);
}
