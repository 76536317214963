import { Button, DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import { LessonGroupResponse } from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { deletedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface LessonGroupDeleteModalProps {
  lesson_group: LessonGroupResponse;
}

export interface LessonGroupDeleteModalRef {
  open: () => void;
  close: () => void;
}

const LessonGroupDeleteModal = forwardRef(
  (props: LessonGroupDeleteModalProps, ref: Ref<LessonGroupDeleteModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onClick = async () => {
      await api.deleteLessonGroup(props.lesson_group.id);
      handleClose();
      dispatch(alertActions.setAlert(deletedMessage('Lesson Group', props.lesson_group.title)));
      navigate('/lesson_group');
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <ModalDialogTitle onClose={handleClose} id="delete-modal-title">
          DELETE LESSON GROUP
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <Typography fontStyle={'italic'} gutterBottom>
              Are you sure you want to delete this lesson group?
            </Typography>
            <Typography gutterBottom>Title: {props.lesson_group.title}</Typography>
            <Typography gutterBottom>Level ID: {props.lesson_group.level_id}</Typography>
            <Typography gutterBottom>Situation ID: {props.lesson_group.situation_id}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onClick}>Delete</Button>
        </DialogActions>
      </ModalDialog>
    );
  },
);

export default LessonGroupDeleteModal;
