import { CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
  },
}));

function LoadingSpinner() {
  const { classes } = useStyles();
  return (
    <div className={classes.root} data-testid="loading-spinner">
      <CircularProgress />
      <Typography>Setting up your app.</Typography>
    </div>
  );
}

export default LoadingSpinner;
