import { Fragment, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions } from '../modules/LessonTemplateSlice';
import { selectLessonTemplateData } from '../modules/LessonTemplateSelector';
import LoadingSection from '../../../components/LoadingSection';
import { LessonTemplatePhraseGroupResponse } from '../../../utilities/apiUtilities';
import LessonTemplatePhraseGroupDeleteModal, {
  LessonTemplatePhraseGroupDeleteModalRef,
} from './LessonTemplatePhraseGroupDeleteModal';
import LessonTemplatePhraseGroupAddModal, {
  LessonTemplatePhraseGroupAddModalRef,
} from './LessonTemplatePhraseGroupAddModal';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paperGroup: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: 'transparent',
  },
}));

const LessonTemplatePhraseGroup = ({ lesson_group_id }: { lesson_group_id: string }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);
  const lessonTemplate = useSelector(selectLessonTemplateData, shallowEqual);
  const [enableAdd, setEnableAdd] = useState(false);
  const [selectedLessonTemplate, setSelectedLessonTemplate] = useState<
    LessonTemplatePhraseGroupResponse | undefined
  >();

  const addRef = useRef<LessonTemplatePhraseGroupAddModalRef>(null);
  const deleteRef = useRef<LessonTemplatePhraseGroupDeleteModalRef>(null);

  const fetchData = (lessonGroupId: string) => {
    return dispatch(actions.fetchLessonTemplate({ lesson_group_id: lessonGroupId }));
  };

  const handleClose = () => {
    setEnableAdd(false);
  };

  const closeCallback = () => {
    setSelectedLessonTemplate(undefined);
  };

  useEffect(() => {
    if (enableAdd) {
      if (addRef.current) {
        addRef.current.open();
      }
    }
  }, [enableAdd]);

  useEffect(() => {
    if (selectedLessonTemplate) {
      if (deleteRef.current) {
        deleteRef.current.open();
      }
    }
  }, [selectedLessonTemplate]);

  useEffect(() => {
    if (!lessonTemplate?.lessonTemplateListLoading) {
      fetchData(lesson_group_id || '');
    }
  }, [lesson_group_id]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container justifyContent="center" alignItems="center">
          <Box display="flex" justifyContent="space-between" mt={2} mb={1} width={'100%'}>
            <Box flexGrow={1} alignSelf="center">
              <Typography variant="subtitle1" component="h5">
                PHRASES
              </Typography>
            </Box>
            <Box>
              <Button
                color="secondary"
                variant="outlined"
                sx={{ marginRight: 0.5 }}
                onClick={() => setEnableAdd(true)}
              >
                ADD NEW
              </Button>
            </Box>
          </Box>
          {showLoading ||
          lessonTemplate.lessonTemplateListLoading ||
          lessonTemplate.lessonTemplateListError ? (
            <LoadingSection error={lessonTemplate.lessonTemplateListError} />
          ) : (
            <TableContainer className={classes.paperGroup} component={Paper}>
              <Table className={classes.paper} aria-label="Sentence table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phrase Group</TableCell>
                    <TableCell align="right">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(lessonTemplate.lessonTemplateList || []).map((innerItem, innerIndex) => (
                    <TableRow
                      key={`${innerItem.id}-${innerIndex}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{innerItem.name}</TableCell>
                      <TableCell>
                        <Link to={'/phrase_group/' + innerItem.phrase_group_id}>
                          {innerItem.phrase_group.title}
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          color="warning"
                          onClick={() => setSelectedLessonTemplate(innerItem)}
                        >
                          <GridDeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {(lessonTemplate.lessonTemplateList || []).length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </div>
      {enableAdd && (
        <LessonTemplatePhraseGroupAddModal
          ref={addRef}
          handleClose={handleClose}
          lesson_group_id={lesson_group_id}
        />
      )}
      {selectedLessonTemplate && (
        <LessonTemplatePhraseGroupDeleteModal
          ref={deleteRef}
          closeCallback={closeCallback}
          lesson_group_id={lesson_group_id}
          lesson_template_phrase_group={selectedLessonTemplate}
        />
      )}
    </Fragment>
  );
};

export default LessonTemplatePhraseGroup;
