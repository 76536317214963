import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

import api from '../../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../../components/ModalDialog';
import {
  LessonGroupResponse,
  PaginationResponse,
  QuestionListResponse,
  SentenceTypeResponse,
} from '../../../utilities/apiUtilities';
import { actions as alertActions } from '../../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

export interface QuestionAddModalRef {
  open: () => void;
  close: () => void;
}

interface QuestionAddModalProps {
  lesson_group_id: string;
  handleClose?: (refetch?: boolean) => void;
}

type FormProps = {
  lesson_group_id: number;
  sentence_type_id: number;
  gpt_prompt?: string;
};

const LessonTemplateAddQuestionModal = forwardRef(
  (props: QuestionAddModalProps, ref: Ref<QuestionAddModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [sentenceTypeOptions, setSentenceTypeOptions] = useState<{ id: string; label: string }[]>(
      [],
    );

    const defaultValues: FormProps = {
      lesson_group_id: parseInt(props.lesson_group_id),
      sentence_type_id: 0,
      gpt_prompt: '',
    };

    const handleOpen = () => setOpen(true);
    const handleClose = (refetch?: boolean) => {
      setOpen(false);
      if (props.handleClose) {
        props.handleClose(refetch);
      }
    };

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .postQuestion<QuestionListResponse>(values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(alertActions.setAlert(createdMessage('Question', 'Question #' + response.id)));
        handleClose(true);
      } else {
        dispatch(alertActions.setAlert(errorCreatedMessage('Question', 'Question')));
        handleClose();
      }
    };

    const formatList = (list: Array<LessonGroupResponse | SentenceTypeResponse>) => {
      return list.map((item) => {
        let label;
        if ('title' in item) {
          label = item.title;
        } else {
          label = 'Unknown';
        }

        return { id: item.id, label: label };
      });
    };

    useEffect(() => {
      (async () => {
        const size = 100;
        const page = 0;
        const sentenceTypes = await api
          .getSentenceTypeList<PaginationResponse<SentenceTypeResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        setSentenceTypeOptions(sentenceTypes);
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={() => handleClose()}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={() => handleClose()} id="add-modal-title">
            ADD New Question
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <SelectElement
                name={'sentence_type_id'}
                label={'Sentence Type'}
                options={sentenceTypeOptions}
                fullWidth
              />
              <TextFieldElement multiline minRows={3} name={'gtp_prompt'} label={'GTP Prompt'} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default LessonTemplateAddQuestionModal;
