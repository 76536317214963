import { Button, DialogActions, DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, TextFieldElement, SwitchElement, SelectElement } from 'react-hook-form-mui';

import api from '../../services/api.service';
import {
  LessonGroupResponse,
  LevelResponse,
  PaginationResponse,
  SituationResponse,
} from '../../utilities/apiUtilities';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import { actions } from './modules/LessonGroupSlice';
import { useDispatch } from 'react-redux';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { errorUpdateMessage, updatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

interface LessonGroupEditModalProps {
  lesson_group: LessonGroupResponse;
}

export interface LessonGroupEditModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  level_id: number;
  situation_id: number;
};

const LessonGroupEditModal = forwardRef(
  (props: LessonGroupEditModalProps, ref: Ref<LessonGroupEditModalRef>) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [situationOptions, setSituationOptions] = useState<{ id: string; label: string }[]>([]);
    const [levelOptions, setLevelOptions] = useState<{ id: string; label: string }[]>([]);

    const defaultValues: FormProps = {
      title: props.lesson_group.title,
      level_id: props.lesson_group.level_id,
      situation_id: props.lesson_group.situation_id,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSuccess = async (values: FormProps) => {
      const response = await api
        .putLessonGroup<LessonGroupResponse>(props.lesson_group.id, values)
        .then((res) => res)
        .catch((err) => undefined);

      if (response) {
        dispatch(actions.fetchDetailSuccess(response));
        dispatch(alertActions.setAlert(updatedMessage('Lesson Group', response.title)));
      } else {
        dispatch(alertActions.setAlert(errorUpdateMessage('Lesson Group', values.title)));
      }

      handleClose();
    };

    const formatList = (list: SituationResponse[] | LevelResponse[]) => {
      return list.map((item) => {
        return { id: item.id, label: item.title };
      });
    };

    useEffect(() => {
      (async () => {
        const size = 100;
        const page = 0;
        const situations = await api
          .getSituationList<PaginationResponse<SituationResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        const levels = await api
          .getLevelList<PaginationResponse<LevelResponse>>(size, page)
          .then((res) => formatList(res.data))
          .catch((err) => []);
        setSituationOptions(situations);
        setLevelOptions(levels);
      })();
    }, []);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <ModalDialog open={open} onClose={handleClose}>
        <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
          <ModalDialogTitle onClose={handleClose} id="edit-modal-title">
            EDIT LESSON GROUP
          </ModalDialogTitle>
          <DialogContent dividers>
            <Stack spacing={3} className={classes.stack}>
              <TextFieldElement name={'title'} label={'Title'} />
              <SelectElement name={'level_id'} label={'Level'} options={levelOptions} fullWidth />
              <SelectElement
                name={'situation_id'}
                label={'Situation'}
                options={situationOptions}
                fullWidth
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type={'submit'}>Save</Button>
          </DialogActions>
        </FormContainer>
      </ModalDialog>
    );
  },
);

export default LessonGroupEditModal;
