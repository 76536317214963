import { Box, Container, Theme, Toolbar } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Header from './Header';
import Sidebar from './Sidebar';
import AlertMessage from '../modules/alert/components/AlertMessage';

const useStyles = makeStyles()((theme: Theme) => ({}));

interface AuthLayoutProps {
  children: React.ReactNode;
}

function AuthLayout({ children }: AuthLayoutProps) {
  const { classes } = useStyles();

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Header open={open} toggleDrawer={toggleDrawer} />
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <AlertMessage />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}

export default AuthLayout;
