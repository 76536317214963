import { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../../services/api.service';
import { SentenceTemplatePhraseGenerateResponse } from '../../../utilities/apiUtilities';
import { actions } from '../modules/LessonTemplateSlice';

interface LessonTemplatePhraseGroupSentenceGenerateProps {
  index: number;
  question_id: string;
  lesson_group_id: string;
  generate_sentence: SentenceTemplatePhraseGenerateResponse[];
  classes: Record<'root' | 'paperGroup' | 'paper', string>;
}

const LessonTemplatePhraseGroupSentenceGenerate: React.FC<
  LessonTemplatePhraseGroupSentenceGenerateProps
> = ({ index, question_id, lesson_group_id, generate_sentence, classes }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (loading) return;

    setLoading(true);
    await api.generateSentenceTemplatePhraseGroup(question_id, lesson_group_id);
    dispatch(actions.fetchOneQuestion({ lesson_group_id, question_id }));
    setLoading(false);
  };

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1} alignSelf="center">
          <Typography variant="subtitle1" component="h5">
            SAMPLE SENTENCE
          </Typography>
        </Box>
      </Box>
      <TableContainer className={classes.paperGroup} component={Paper}>
        <Table className={classes.paper} aria-label="Sentence table">
          {/* <TableHead>
            <TableRow>
              <TableCell>Template</TableCell>
              <TableCell>Sentence</TableCell>
              <TableCell>Sentence Template ID</TableCell>
              <TableCell>Language ID</TableCell>
              <TableCell>Phrase Group ID</TableCell>
              <TableCell>Phrase ID</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {(generate_sentence || []).map((innerItem, innerIndex) => (
              <TableRow
                key={`${index}-${innerIndex}-sentence-template-generate`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{innerItem.sentence}</TableCell>
                {/* <TableCell>{innerItem.sentence}</TableCell>
                <TableCell>
                  <Link to={'/sentence_template/' + innerItem.sentence_template_id}>
                    {innerItem.sentence_template_id}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={'/language/' + innerItem.language_id}>{innerItem.language_id}</Link>
                </TableCell>
                <TableCell>
                  <Link to={'/phrase_group/' + innerItem.phrase_group_id}>
                    {innerItem.phrase_group_id}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={'/phrase/' + innerItem.phrase_id}>{innerItem.phrase_id}</Link>
                </TableCell> */}
              </TableRow>
            ))}
            {(generate_sentence || []).length === 0 && (
              <TableRow>
                <TableCell colSpan={1} align="center">
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" className={classes.paperGroup}>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClick}
          disabled={(generate_sentence || []).length === 0 || loading}
        >
          GENERATE SAMPLE SENTENCE
        </Button>
      </Box>
    </Fragment>
  );
};

export default LessonTemplatePhraseGroupSentenceGenerate;
