import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import { PhraseState, actions } from './modules/PhraseSlice';
import LoadingSection from '../../components/LoadingSection';
import { selectPhraseData } from './modules/PhraseSelector';
import PhraseList from './PhraseList';
import PhraseAddModal, { PhraseAddModalRef } from './PhraseAddModal';
import { defaultPagination } from '../../utilities/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  rootAlt: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(4, 0),
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

interface PhrasePageProps {
  phraseGroupId?: number;
}

function PhrasePage({ phraseGroupId }: PhrasePageProps = {}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const phrases: PhraseState = useSelector(selectPhraseData, shallowEqual);
  const [showLoading, setShowLoading] = useState(true);
  const addRef = useRef<PhraseAddModalRef>(null);

  const fetchData = (
    size = phrases.meta?.per_page || defaultPagination.size,
    page = phrases?.meta?.current_page || defaultPagination.page,
    phrase_group_id = phraseGroupId,
  ) => {
    return dispatch(actions.fetch({ size, page, phrase_group_id }));
  };

  useEffect(() => {
    if (!phrases?.loading) {
      const size = Number(searchParams.get('size')) || defaultPagination.size;
      const page = Number(searchParams.get('page')) || defaultPagination.page;
      fetchData(size, page, phraseGroupId);
    }
  }, [phraseGroupId, location, searchParams]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowPhraseList = () => {
    if (showLoading || phrases.loading || phrases.error) {
      return <LoadingSection error={phrases.error} />;
    }
    return (
      <PhraseList state={phrases} setSearchParams={setSearchParams} phraseGroupId={phraseGroupId} />
    );
  };

  return (
    <div className={phraseGroupId ? classes.rootAlt : classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          {!phraseGroupId && (
            <Box display="flex">
              <Box flexGrow={1} alignSelf="center">
                <Breadcrumbs
                  id="alerts-breadcrumbs"
                  links={[
                    { path: '/', name: 'Dashboard' },
                    { path: '/phrase', name: 'Phrase' },
                  ]}
                />
              </Box>
              <Box data-testid="alerts-refresh-button">
                <Button
                  onClick={() => {
                    if (addRef.current) addRef.current.open();
                  }}
                  startIcon={<Add />}
                  color="primary"
                  variant="outlined"
                >
                  Add Phrase
                </Button>
              </Box>
            </Box>
          )}
          <Paper className={classes.paper} elevation={0}>
            <Box display="flex">
              <Box flexGrow={1} alignSelf="center">
                {!(showLoading || phrases.loading || phrases.error) && (
                  <Typography variant="h5" color="textPrimary" marginBottom={1}>
                    {phrases?.meta?.total || 0} Phrase{(phrases?.meta?.total || 0) > 1 ? 's' : ''}
                  </Typography>
                )}
              </Box>
              <Box data-testid="alerts-add-button">
                {phraseGroupId && (
                  <Button
                    onClick={() => {
                      if (addRef.current) addRef.current.open();
                    }}
                    startIcon={<Add />}
                    color="primary"
                    variant="outlined"
                  >
                    Add Phrase
                  </Button>
                )}
              </Box>
            </Box>
            <ShowPhraseList />
          </Paper>
        </Grid>
      </Grid>
      <PhraseAddModal ref={addRef} phraseGroupId={phraseGroupId} />
    </div>
  );
}

export default PhrasePage;
