import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AlertState = {
  severity: AlertColor;
  open: boolean;
  title?: string;
  message?: string;
};

export const initialState: AlertState = {
  title: undefined,
  message: undefined,
  severity: 'info',
  open: false,
};

export const LessonSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertState>) => {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.open = action.payload.open;
    },
    clearAlert: (state) => {
      state.title = undefined;
      state.message = undefined;
      state.severity = 'info';
      state.open = false;
    },
  },
});

export const { name, actions, reducer } = LessonSlice;
