import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorResponse,
  PaginationMetaResponse,
  PaginationResponse,
  SituationResponse,
} from '../../../utilities/apiUtilities';

export type SituationState = {
  data: SituationResponse[];
  loading: boolean;
  paginationLoading: boolean;
  error?: ErrorResponse;
  meta?: PaginationMetaResponse;
  situationDetail?: SituationResponse;
};

export const initialState: SituationState = {
  data: [],
  loading: false,
  paginationLoading: false,
};

export const SituationSlice = createSlice({
  name: 'situation',
  initialState,
  reducers: {
    fetch(
      state,
      action: PayloadAction<{ size: number; page: number; situation_group_id?: number }>,
    ) {
      if (action.payload.page > 1) {
        state.paginationLoading = true;
      } else {
        state.loading = true;
      }
      state.error = undefined;
      state.data = [];
    },
    fetchSuccess(state, action: PayloadAction<PaginationResponse<SituationResponse>>) {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchFailure(state, action: PayloadAction<ErrorResponse>) {
      state.data = [];
      state.meta = undefined;
      state.error = action.payload;
      state.loading = false;
      state.paginationLoading = false;
    },
    fetchDetail(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
      state.error = undefined;
      state.situationDetail = undefined;
    },
    fetchDetailSuccess(state, action: PayloadAction<SituationResponse>) {
      state.situationDetail = action.payload;
      state.loading = false;
    },
    fetchDetailFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { name, actions, reducer } = SituationSlice;
