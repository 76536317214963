import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

function FindAccount() {
  const { classes } = useStyles();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {};

  return (
    <Container component="main" maxWidth="xs">
      <Box className={classes.root}>
        <Typography component="h1" variant="h5">
          Find your account to reset password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Request password reset
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default FindAccount;
