import { Breadcrumbs as MuiBC, Link, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

interface BreadcrumbsProps {
  id: string;
  links: { name: string; path: string }[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
}));

const Breadcrumbs = ({ id, links }: BreadcrumbsProps) => {
  const { classes } = useStyles();

  return (
    <MuiBC aria-label="breadcrumb" id={id} className={classes.root}>
      {links.map((link, index) => {
        if (index === links.length - 1) {
          return (
            <Link
              component={RouterLink}
              to={link.path}
              key={link.path}
              color="textPrimary"
              aria-current="page"
            >
              {link.name}
            </Link>
          );
        }
        return (
          <Link component={RouterLink} to={link.path} key={link.path} color="primary">
            {link.name}
          </Link>
        );
      })}
    </MuiBC>
  );
};

export default Breadcrumbs;
