import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { PhraseGroupState } from './PhraseGroupSlice';

const PhraseGroupData = (state: RootState): PhraseGroupState => state.phraseGroup;

export const selectPhraseGroupData: Selector<RootState, PhraseGroupState> = createSelector(
  PhraseGroupData,
  (phraseGroup) => phraseGroup,
);

export const selectPhraseGroupDetail = createSelector(
  PhraseGroupData,
  (phraseGroup) => phraseGroup?.phraseGroupDetail,
);
