import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { SentenceTemplateState } from './SentenceTemplateSlice';

const SentenceTemplateData = (state: RootState): SentenceTemplateState => state.sentenceTemplate;

export const selectSentenceTemplateData: Selector<RootState, SentenceTemplateState> =
  createSelector(SentenceTemplateData, (sentenceTemplate) => sentenceTemplate);

export const selectSentenceTemplateDetail = createSelector(
  SentenceTemplateData,
  (sentenceTemplate) => sentenceTemplate?.sentenceTemplateDetail,
);
