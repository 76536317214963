import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { LanguageState } from './LanguageSlice';

const LanguageData = (state: RootState): LanguageState => state.language;

export const selectLanguageData: Selector<RootState, LanguageState> = createSelector(
  LanguageData,
  (language) => language,
);

export const selectLanguageDetail = createSelector(
  LanguageData,
  (language) => language?.languageDetail,
);
