import { Button, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api.service';
import ModalDialog, { ModalDialogTitle } from '../../components/ModalDialog';
import {
  PaginationResponse,
  QuestionListResponse,
  SentenceTemplateResponse,
} from '../../utilities/apiUtilities';
import { actions as alertActions } from '../alert/modules/AlertSlice';
import { createdMessage, errorCreatedMessage } from '../alert/modules/AlertUtil';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  stack: {
    width: '400px',
  },
}));

export interface SentenceTemplateAddModalRef {
  open: () => void;
  close: () => void;
}

type FormProps = {
  title: string;
  number: number;
  question_id: number;
};

const SentenceTemplateAddModal = forwardRef(({}, ref: Ref<SentenceTemplateAddModalRef>) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questionOptions, setQuestionOptions] = useState<{ id: string; label: string }[]>([]);

  const defaultValues: FormProps = {
    title: '',
    number: 0,
    question_id: 0,
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSuccess = async (values: FormProps) => {
    const response = await api
      .postSentenceTemplate<SentenceTemplateResponse>(values)
      .then((res) => res)
      .catch((err) => undefined);

    if (response) {
      dispatch(alertActions.setAlert(createdMessage('Sentence Template', response.title)));
      navigate('/sentence_template/' + response.id);
    } else {
      dispatch(alertActions.setAlert(errorCreatedMessage('Sentence Template', values.title)));
      handleClose();
    }
  };

  const formatList = (list: QuestionListResponse[]) => {
    return list.map((item) => {
      return { id: item.id, label: `Question #${item.id}` };
    });
  };

  useEffect(() => {
    (async () => {
      const size = 100;
      const page = 0;
      const questions = await api
        .getQuestionList<PaginationResponse<QuestionListResponse>>(size, page)
        .then((res) => formatList(res.data))
        .catch((err) => []);

      setQuestionOptions(questions);
    })();
  }, []);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <ModalDialog open={open} onClose={handleClose}>
      <FormContainer defaultValues={defaultValues} onSuccess={onSuccess}>
        <ModalDialogTitle onClose={handleClose} id="add-modal-title">
          ADD SENTENCE TEMPLATE
        </ModalDialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} className={classes.stack}>
            <TextFieldElement name={'title'} label={'Title'} />
            <TextFieldElement name={'number'} label={'Number'} />
            <SelectElement
              name={'question_id'}
              label={'Question ID'}
              options={questionOptions}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type={'submit'}>Save</Button>
        </DialogActions>
      </FormContainer>
    </ModalDialog>
  );
});

export default SentenceTemplateAddModal;
