import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LoadingSection from '../../components/LoadingSection';
import Breadcrumbs from '../../components/Breadcrumbs';
import { actions } from './modules/SituationSlice';
import { selectSituationData } from './modules/SituationSelector';
import SituationEditModal, { SituationEditModalRef } from './SituationEditModal';
import SituationDeleteModal, { SituationDeleteModalRef } from './SituationDeleteModal';
import ImageViewer, { ImageViewerRef } from '../../components/ImageViewer';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

const actionOptions = ['Edit', 'Delete'];

interface SituationDetailPageProps {
  situationGroupId?: number;
}

function SituationDetailPage({ situationGroupId }: SituationDetailPageProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(true);
  const situation = useSelector(selectSituationData, shallowEqual);
  const params = useParams();
  const [open, setOpen] = useState(false);
  const imageViewerRef = useRef<ImageViewerRef>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<SituationEditModalRef>(null);
  const deleteRef = useRef<SituationDeleteModalRef>(null);

  const handleImageClick = () => {
    if (imageViewerRef.current) {
      imageViewerRef.current.open();
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (index === 0) {
      editRef.current?.open();
    }

    if (index === 1) {
      deleteRef.current?.open();
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const fetchData = (situationId: string) => {
    return dispatch(actions.fetchDetail({ id: situationId }));
  };

  useEffect(() => {
    if (!situation?.loading) {
      fetchData(params.id || '');
    }
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowSituationDetail = () => {
    if (showLoading || situation.loading || situation.error) {
      return <LoadingSection error={situation.error} />;
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Title
              </TableCell>
              <TableCell align="right">{situation.situationDetail?.title}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Description
              </TableCell>
              <TableCell align="right">{situation.situationDetail?.description}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Image
              </TableCell>
              <TableCell align="right">
                {situation.situationDetail?.image && (
                  <Button size="small" onClick={handleImageClick}>
                    {situation.situationDetail?.image?.original_name}
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Situation Group ID
              </TableCell>
              <TableCell align="right">
                <Link to={`/situation_group/${situation.situationDetail?.situation_group_id}`}>
                  {situation.situationDetail?.situation_group_id}
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  ...(situationGroupId !== undefined || params.situation_group_id !== undefined
                    ? [
                        { path: '/situation_group', name: 'Situation Group' },
                        {
                          path:
                            '/situation_group/' +
                            (situationGroupId || params.situation_group_id || ''),
                          name:
                            (situationGroupId || params.situation_group_id || '').toString() ||
                            'Unknown',
                        },
                        {
                          path:
                            '/situation_group/' +
                            (situationGroupId || params.situation_group_id || ''),
                          name: 'Situation',
                        },
                        {
                          path:
                            '/situation_group/' +
                            (situationGroupId || params.situation_group_id || '') +
                            '/situation/' +
                            params.id,
                          name: params.id || 'Unknown',
                        },
                      ]
                    : [
                        { path: '/situation', name: 'Situation' },
                        { path: '/situation/' + params.id, name: params.id || 'Unknown' },
                      ]),
                ]}
              />
            </Box>
            <Box>
              <ButtonGroup ref={anchorRef}>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={handleToggle}
                >
                  Actions
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="button-menu" autoFocusItem>
                          {actionOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {/* <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {id || 'Unknown'}
              </Typography> */}
            <ShowSituationDetail />
          </Paper>
        </Grid>
      </Grid>
      {situation?.situationDetail && (
        <Fragment>
          <SituationEditModal
            ref={editRef}
            situation={situation.situationDetail}
            situationGroupId={situationGroupId || parseInt(params.situation_group_id || '')}
          />
          <SituationDeleteModal ref={deleteRef} situation={situation.situationDetail} />
        </Fragment>
      )}
      {situation?.situationDetail?.image && (
        <ImageViewer ref={imageViewerRef} image={situation?.situationDetail?.image} />
      )}
    </div>
  );
}

export default SituationDetailPage;
