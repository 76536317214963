import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LoadingSection from '../../components/LoadingSection';
import Breadcrumbs from '../../components/Breadcrumbs';
import { actions } from './modules/SituationGroupSlice';
import { selectSituationGroupData } from './modules/SituationGroupSelector';
import SituationGroupEditModal, { SituationGroupEditModalRef } from './SituationGroupEditModal';
import SituationGroupDeleteModal, {
  SituationGroupDeleteModalRef,
} from './SituationGroupDeleteModal';
import ImageViewer, { ImageViewerRef } from '../../components/ImageViewer';
import SituationPage from '../situation/SituationPage';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

const actionOptions = ['Edit', 'Delete'];

function SituationGroupDetailPage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(true);
  const situationGroup = useSelector(selectSituationGroupData, shallowEqual);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const imageViewerRef = useRef<ImageViewerRef>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<SituationGroupEditModalRef>(null);
  const deleteRef = useRef<SituationGroupDeleteModalRef>(null);

  const handleImageClick = () => {
    if (imageViewerRef.current) {
      imageViewerRef.current.open();
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (index === 0) {
      editRef.current?.open();
    }

    if (index === 1) {
      deleteRef.current?.open();
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const fetchData = (levelId: string) => {
    return dispatch(actions.fetchDetail({ id: levelId }));
  };

  useEffect(() => {
    if (!situationGroup?.loading) {
      fetchData(id || '');
    }
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowSituationGroupDetail = () => {
    if (showLoading || situationGroup.loading || situationGroup.error) {
      return <LoadingSection error={situationGroup.error} />;
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Title
              </TableCell>
              <TableCell align="right">{situationGroup.situationGroupDetail?.title}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Description
              </TableCell>
              <TableCell align="right">
                {situationGroup.situationGroupDetail?.description}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Image
              </TableCell>
              <TableCell align="right">
                {situationGroup.situationGroupDetail?.image && (
                  <Button size="small" onClick={handleImageClick}>
                    {situationGroup.situationGroupDetail?.image?.original_name}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/situation_group', name: 'Situation Group' },
                  { path: '/situation_group/' + id, name: id || 'Unknown' },
                ]}
              />
            </Box>
            <Box>
              <ButtonGroup ref={anchorRef}>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={handleToggle}
                >
                  Actions
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="button-menu" autoFocusItem>
                          {actionOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {/* <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {id || 'Unknown'}
              </Typography> */}
            <ShowSituationGroupDetail />
          </Paper>
        </Grid>
      </Grid>
      {situationGroup?.situationGroupDetail && (
        <Fragment>
          <SituationGroupEditModal
            ref={editRef}
            situationGroup={situationGroup.situationGroupDetail}
          />
          <SituationGroupDeleteModal
            ref={deleteRef}
            situationGroup={situationGroup.situationGroupDetail}
          />
        </Fragment>
      )}
      {situationGroup?.situationGroupDetail?.image && (
        <ImageViewer ref={imageViewerRef} image={situationGroup?.situationGroupDetail?.image} />
      )}
      <SituationPage situationGroupId={parseInt(id || '')} />
    </div>
  );
}

export default SituationGroupDetailPage;
