import {
  Avatar,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import api from '../../services/api.service';
import { storeAccessToken } from '../../utilities/utils';
import { LoginResponse } from '../../utilities/apiUtilities';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

function Login() {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const response = await api
      .login({
        email: data.get('email') as string,
        password: data.get('password') as string,
      })
      .then((res) => {
        return res as LoginResponse;
      })
      .catch((err) => {
        return err;
      });

    if (response && response.access_token !== undefined) {
      storeAccessToken(response.access_token);
      window.location.href = window.location.origin + '/';
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box className={classes.root}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="/find-account" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
