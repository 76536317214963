import { createSelector, Selector } from '@reduxjs/toolkit';

import { RootState } from '../../../reducers';
import { SituationState } from './SituationSlice';

const SituationData = (state: RootState): SituationState => state.situation;

export const selectSituationData: Selector<RootState, SituationState> = createSelector(
  SituationData,
  (situation) => situation,
);

export const selectSituationDetail = createSelector(
  SituationData,
  (situation) => situation?.situationDetail,
);
