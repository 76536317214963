import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../services/api.service';
import {
  ErrorResponse,
  LessonResponse,
  LessonDetailResponse,
  TranslatedSentenceResponse,
  PaginationResponse,
} from '../../../utilities/apiUtilities';
import { actions } from './LessonSlice';

export function* fetchLessonList(
  action: PayloadAction<{ size: number; page: number; lesson_group_id?: number }>,
): SagaIterator {
  const { size, page } = action.payload;
  const lessonGroupId = action.payload.lesson_group_id ? action.payload.lesson_group_id : undefined;
  try {
    const res: PaginationResponse<LessonResponse> = yield call(
      api.getLessonList,
      size,
      page,
      lessonGroupId,
    );
    yield put(actions.fetchSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchFailure(res));
  }
}

export function* fetchLessonId(action: PayloadAction<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  try {
    const languageList: LessonResponse[] = yield call(api.getPublishedLanguageExcluding, 'en');
    const res: LessonDetailResponse = yield call(api.getLessonById, id);
    yield put(actions.setLanguageList(languageList));
    yield put(actions.fetchDetailSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchDetailFailure(res));
  }
}

export function* fetchTranslatedSentence(
  action: PayloadAction<{ lessonId: string; languageId: string; language: string }>,
): SagaIterator {
  const { lessonId, languageId } = action.payload;
  try {
    const res: TranslatedSentenceResponse[] = yield call(
      api.getTranslatedSentence,
      lessonId,
      languageId,
    );
    yield put(actions.fetchTranslatedSentenceSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.fetchTranslatedSentenceFailure(res));
  }
}

export function* fetchGeneratedTranslatedSentence(
  action: PayloadAction<{ sentenceId: string; languageId: string }>,
): SagaIterator {
  const { sentenceId, languageId } = action.payload;
  try {
    const res: TranslatedSentenceResponse = yield call(
      api.generateTranslatedSentence,
      sentenceId,
      languageId,
    );
    yield put(actions.generateTranslatedSentenceSuccess(res));
  } catch (error) {
    const res = error as ErrorResponse;
    yield put(actions.generateTranslatedSentenceFailure(res));
  }
}

export default function* LessonSaga(): SagaIterator {
  yield all([
    yield takeEvery(actions.fetch, fetchLessonList),
    yield takeEvery(actions.fetchDetail, fetchLessonId),
    yield takeEvery(actions.fetchTranslatedSentence, fetchTranslatedSentence),
    yield takeEvery(actions.generateTranslatedSentence, fetchGeneratedTranslatedSentence),
  ]);
}
