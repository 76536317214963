import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LoadingSection from '../../components/LoadingSection';
import Breadcrumbs from '../../components/Breadcrumbs';
import { actions } from './modules/LessonGroupSlice';
import { selectLessonGroupData } from './modules/LessonGroupSelector';
import LessonGroupEditModal, { LessonGroupEditModalRef } from './LessonGroupEditModal';
import LessonGroupDeleteModal, { LessonGroupDeleteModalRef } from './LessonGroupDeleteModal';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 4),
    paddingTop: 0,
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: theme.spacing(1),
  },
}));

const actionOptions = ['Edit', 'Delete'];

function LessonGroupDetailPage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const [showLoading, setShowLoading] = useState(true);
  const lessonGroup = useSelector(selectLessonGroupData, shallowEqual);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<LessonGroupEditModalRef>(null);
  const deleteRef = useRef<LessonGroupDeleteModalRef>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (index === 0) {
      editRef.current?.open();
    }

    if (index === 1) {
      deleteRef.current?.open();
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const onClickLesson = () => {
    navigation(`/lesson_group/${id}/lesson`);
  };

  const onClickLessonTemplate = () => {
    navigation(`/lesson_group/${id}/lesson_template`);
  };

  const fetchData = (lessonGroupId: string) => {
    return dispatch(actions.fetchDetail({ id: lessonGroupId }));
  };

  useEffect(() => {
    if (!lessonGroup?.loading) {
      fetchData(id || '');
    }
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(false), 1000);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const ShowLessonGroupDetail = () => {
    if (showLoading || lessonGroup.loading || lessonGroup.error) {
      return <LoadingSection error={lessonGroup.error} />;
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Title
              </TableCell>
              <TableCell align="right">{lessonGroup.lessonGroupDetail?.title}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Level ID
              </TableCell>
              <TableCell align="right">
                <Link to={`/level/${lessonGroup.lessonGroupDetail?.level_id}`}>
                  {lessonGroup.lessonGroupDetail?.level_id}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Situation ID
              </TableCell>
              <TableCell align="right">
                <Link to={`/situation/${lessonGroup.lessonGroupDetail?.situation_id}`}>
                  {lessonGroup.lessonGroupDetail?.situation_id}
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1} alignSelf="center">
              <Breadcrumbs
                id="alerts-breadcrumbs"
                links={[
                  { path: '/', name: 'Dashboard' },
                  { path: '/lesson_group', name: 'Lesson Group' },
                  { path: '/lesson_group/' + id, name: id || 'Unknown' },
                ]}
              />
            </Box>
            <Box>
              {lessonGroup?.lessonGroupDetail && (
                <Fragment>
                  <Button
                    color="secondary"
                    variant="outlined"
                    sx={{ marginRight: 0.5 }}
                    onClick={onClickLesson}
                  >
                    Lessons ({lessonGroup?.lessonGroupDetail?.number_of_lessons || 0})
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    sx={{ marginRight: 0.5 }}
                    onClick={onClickLessonTemplate}
                  >
                    Lesson Template
                  </Button>
                </Fragment>
              )}
              <ButtonGroup ref={anchorRef}>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={handleToggle}
                >
                  Actions
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="button-menu" autoFocusItem>
                          {actionOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <Paper className={classes.paper} elevation={0}>
            {/* <Typography variant="h5" color="textPrimary" marginBottom={1}>
                {id || 'Unknown'}
              </Typography> */}
            <ShowLessonGroupDetail />
          </Paper>
        </Grid>
      </Grid>
      {lessonGroup?.lessonGroupDetail && (
        <Fragment>
          <LessonGroupEditModal ref={editRef} lesson_group={lessonGroup.lessonGroupDetail} />
          <LessonGroupDeleteModal ref={deleteRef} lesson_group={lessonGroup.lessonGroupDetail} />
        </Fragment>
      )}
    </div>
  );
}

export default LessonGroupDetailPage;
